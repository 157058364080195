import { Tooltip } from "@chakra-ui/react";
import React from "react";

export const TableRow = ({ rowKey, children, toolTip }: { rowKey: string; children: any; toolTip?: string }) => {
  return (
    <tr className="border-b-2 border-solid border-gray-200 ">
      <td className="py-2 text-gray-400 pr-4">
        <Tooltip label={toolTip} hasArrow>
          <p>{rowKey}</p>
        </Tooltip>
      </td>
      <td className="py-2 font-semibold">{children}</td>
    </tr>
  );
};
