export const localizations = {
  dutch: {
    title: "Download je foto's hier:",
    subtitle: ["Ga naar", "of scan de onderstaande QR-code"],
    duration: "Je foto's worden binnen 1 tot 5 dagen geüpload",
  },
  german: {
    title: "Laden Sie hier Ihre Fotos herunter:",
    subtitle: ["Gehe zu", "oder scanne den QR-Code unten"],
    duration: "Ihre Fotos werden in 1 bis 5 Tagen hochgeladen",
  },
  france: {
    title: "Téléchargez vos photos ici:",
    subtitle: ["Allez sur", "ou scannez le code QR ci-dessous"],
    duration: "Vos photos seront téléchargées dans 1 à 5 jours",
  },
  english: {
    title: "Download your photos here:",
    subtitle: ["Go to", "or scan the QR code below"],
    duration: "Your photos will be available in 1 to 5 days",
  },
};
