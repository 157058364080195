import Button from "components/forms/Button";
import SearchBar from "components/forms/SearchBar";
import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import Modal from "./Modal";
import firebase from "../../firebase";
import { Code } from "components/layout/Code";
export const ModalCodeSearch = ({ close }: { close: () => void }) => {
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [error, setError] = useState("");

  const search = async () => {
    if (filter == null || filter === "") return;
    setLoading(true);
    setError("");
    setCode(null);
    try {
      const q = await firebase.firestore().collection("codes").doc(filter).get();
      if (q.exists) {
        const d = { id: q.id, ...q.data() };
        setCode(d);
      } else {
        setError(`Code ${filter} niet gevonden in jouw account.`);
      }
    } catch {
      setError(`Code ${filter} niet gevonden in jouw account.`);
    }
    setLoading(false);
  };

  return (
    <Modal title="Vind een code" close={close}>
      <div className="flex gap-4 items-center mt-4">
        <SearchBar setFilter={setFilter} value={filter || ""} placeholder="Vul code in" />
        <Button onClick={search}>Zoek</Button>
      </div>
      <div className="mt-4">
        <h1 className="md:text-2xl text-xl font-semibold">Resultaat</h1>
        <div className="mt-4">
          {loading && <FaSpinner className="icon-spin text-slate-400 text-2xl" />}
          {error !== "" && !loading && <p>{error}</p>}
          {code != null && <Code key={code.id} code={code} />}
        </div>
      </div>
    </Modal>
  );
};
