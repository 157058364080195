import { CircularProgress, useToast } from "@chakra-ui/react";
import Button from "components/forms/Button";
import { LargeIcon } from "components/icons/LargeIcon";
import { PageSideBarLayout } from "components/layout/PageSideBarLayout";
import React, { useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import firebase from "../../firebase";

const VerifyEmailSucces = () => {
  return (
    <div>
      <LargeIcon>
        <FaCheck />
      </LargeIcon>
      <p className="mb-8">Je email is succesvol geverifieerd.</p>
      <Button>
        <Link to="/dashboard">Verder</Link>
      </Button>
    </div>
  );
};

const VerifyEmailError = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const handleResend = async () => {
    setLoading(true);
    try {
      await firebase.auth().currentUser.sendEmailVerification();
      toast({
        title: "Email is opnieuw verzonden!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Probleem met het verzenden van de email",
        description: "Controleer of je al een email hebt ontvangen",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <LargeIcon>
        <FaTimes />
      </LargeIcon>
      <p className="mb-8">Deze verificatielink is verlopen.</p>
      <Button onClick={handleResend} loading={loading}>
        Stuur nieuwe email
      </Button>
    </div>
  );
};

export const VerifyEmailHandler = ({ oobCode }: { oobCode: string }) => {
  const [loading, setLoading] = useState(true);
  const [verifyError, setVerifyError] = useState(false);
  const [verifySuccess, setVerifySuccess] = useState(false);
  const history = useHistory();

  const handleVerifyEmail = async () => {
    try {
      await firebase.auth().applyActionCode(oobCode);
      firebase.auth().currentUser.reload();
      setVerifySuccess(true);
    } catch (err) {
      setVerifyError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user !== null && user.emailVerified) history.push("/dashboard");
    });
  }, [history]);

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  return (
    <PageSideBarLayout title={loading ? "" : verifyError ? "Ongeldige link" : "Verificatie gelukt"}>
      {loading && (
        <div className="mt-8">
          <CircularProgress isIndeterminate capIsRound={true} color="#3b82f6" size={100} thickness={8} />
        </div>
      )}
      {verifyError && <VerifyEmailError />}
      {verifySuccess && <VerifyEmailSucces />}
    </PageSideBarLayout>
  );
};
