import React, { useContext } from "react";
import { ModalPrompt } from "./ModalPrompt";
import firebase from "../../firebase";
import { DashboardContext } from "contexts/DashboardContext";

export const ModalEmailVerification = ({ close }) => {
  const { sendToast } = useContext(DashboardContext);
  return (
    <ModalPrompt
      title="Verifieer je e-mailadres"
      description="Om QR-codes te kunnen maken, moet je eerst je e-mailadres verifiëren. Controleer je emailbox en klik op de link in de e-mail."
      close={close}
      onCancel={() => {
        firebase.auth().currentUser.sendEmailVerification({
          url: "https://app.mijnfotohoek.nl/dashboard",
        });
        close();
        sendToast("E-mail verstuurd", "Controleer je inbox", "success");
      }}
      cancelText="Opnieuw verzenden"
      confirmText="Oké"
      onConfirm={close}
    />
  );
};
