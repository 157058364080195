import { motion } from "framer-motion";
import React from "react";
import { FaSpinner } from "react-icons/fa";

interface Props {
  disabled?: Boolean;
  children: any;
  color?: string;
  size?: string;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  onClick?: () => void;
  loading?: Boolean;
}

export default function Button({
  disabled = false,
  children,
  color = "bg-blue-200 text-blue-500",
  size = "text-lg",
  type,
  className,
  onClick,
  loading = false,
}: Props) {
  return (
    <motion.button
      type={type}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.1 }}
      onClick={() => {
        if (!loading && onClick != null) {
          onClick();
        }
      }}
      className={`py-1 px-6 rounded-xl hover:opacity-80 transition-all font-semibold  ${className} ${size} ${color} ${
        loading || (disabled && `cursor-not-allowed opacity-80`)
      } `}
    >
      <div className="flex items-center justify-center">
        {loading && <FaSpinner className="icon-spin absolute" />}
        <span className={`${loading && `invisible`} flex items-center justify-center gap-2`}>{children}</span>
      </div>
    </motion.button>
  );
}
