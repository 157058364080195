import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import QRCode from "qrcode.react";
import React from "react";
import { localizations } from "./localizations";

function PrintCode({ code, rotate = false, language, email, period, expires }) {
  const width = 21;
  const height = 29.7;

  return (
    <div
      className="text-center bg-white flex flex-col justify-between p-16"
      style={{ width: `${width}cm`, height: `${height}cm` }}
    >
      <div></div>
      <div className="flex flex-col gap-0 pr-1 ">
        <h1 className="font-semibold text-gray-900 text-5xl">
          <FontAwesomeIcon icon={faCamera} /> {localizations[language].title}
        </h1>
        <p className="text-gray-900 pl-1 text-2xl mt-2 font-bold">
          {localizations[language].subtitle[0]}{" "}
          <a href={`https://mijnfotohoek.nl/code/${code}`} className="text-gray-900 underline underline-offset-4">
            mijnfotohoek.nl/code/{code}
          </a>
          <br />
          {localizations[language].subtitle[1]}
        </p>
      </div>
      <div className="flex justify-center relative">
        <QRCode size="500" value={`https://mijnfotohoek.nl/code/${code}`} />
      </div>
      <div>
        <p className="text-gray-900 text-2xl">Je foto's worden binnen {period} geüpload</p>
      </div>
      <p className="text-3xl">{email !== "null" && email}</p>
      {expires && (
        <p className="text-gray-900 text-lg">
          Deze code is geldig tot {moment(expires, "YYYY-MM-DD").format("D MMM YYYY")}
        </p>
      )}
      <div></div>
    </div>
  );
}

export default PrintCode;
