import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { DashboardContext } from "contexts/DashboardContext";
import ScrollToTop from "utils/ScrollToTop";
import Account from "./Account";
import Manual from "./Manual";
import Support from "./Support";
import DashboardHome from "./DashboardHome";
import firebase from "firebase";
import Loading from "components/layout/Loading";
import Upgrade from "./Upgrade";
import { ProcessOrder } from "./ProcessOrder";

export default withRouter(function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const { userData } = useContext(DashboardContext);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user === null) props.history.push("/");
      if (user !== null && !user.emailVerified) props.history.push("/verifieer/");
      setLoading(false);
    });
  }, [props.history]);

  return loading || userData == null ? (
    <Loading />
  ) : (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/dashboard/" component={DashboardHome} />
        <Route path="/dashboard/account/" component={Account} />
        <Route path="/dashboard/support/" component={Support} />
        <Route path="/dashboard/handleiding/" component={Manual} />
        <Route path="/dashboard/upgrade/" component={Upgrade} />
        <Route path="/dashboard/verwerken/:id" component={ProcessOrder} />
      </Switch>
    </BrowserRouter>
  );
});
