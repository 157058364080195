// import { CircularProgress } from "@chakra-ui/react";
import { CircularProgress } from "@chakra-ui/react";
import Card from "components/cards/Card";
import Page from "components/layout/Page";
import React, { useEffect, useState } from "react";
import { FaCheck, FaClock, FaTimes } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { Order } from "@mijnfotohoek-types";
import firebase from "../firebase";
import Confetti from "react-confetti";
import Button from "components/forms/Button";

const Status = ({
  title,
  description,
  icon,
  loading,
  redirectButton = true,
}: {
  title: string;
  description: string;
  icon?: React.ReactNode | null;
  loading?: boolean;
  redirectButton?: boolean;
}) => {
  return (
    <div className="items-center flex flex-col">
      {loading ? (
        <CircularProgress
          className="mx-auto"
          isIndeterminate
          capIsRound={true}
          color="#3b82f6"
          size={200}
          thickness={4}
        />
      ) : (
        <div className="w-32 h-32 text-blue-500 bg-slate-200 rounded-full flex items-center justify-center text-4xl">
          {icon}
        </div>
      )}

      <h1 className="text-xl font-bold text-blue-500 mt-8">{title}</h1>
      <p className="max-w-xs text-center text-slate-500">{description}</p>
      <Button className="mt-8">
        <Link to="/dashboard">Naar dashboard</Link>
      </Button>
    </div>
  );
};

export const ProcessOrder = () => {
  // @ts-ignore
  const { id } = useParams();
  const [order, setOrder] = useState<Order | null>(null);
  useEffect(() => {
    if (id != null) {
      const unsubscribe = firebase
        .firestore()
        .collection("orders")
        .doc(id)
        .onSnapshot((doc) => {
          setOrder(doc.data() as Order);
        });
      return () => unsubscribe();
    }
  }, [id]);

  return (
    order != null && (
      <Page>
        {order.status === "paid" && <Confetti recycle={false} />}
        <Card className="max-w-md mx-auto">
          {order.status === "pending" && (
            <Status
              title="Order aan het verwerken"
              description="We zijn je order aan het verwerken. Een ogenblik geduld."
              loading={true}
              redirectButton={false}
            />
          )}
          {order.status === "paid" && (
            <Status
              title="Geslaagd!"
              description="Bedankt voor je betaling! Je abonnement is nu actief."
              icon={<FaCheck />}
            />
          )}

          {order.status === "failed" && (
            <Status
              title="Mislukt"
              description={`Er is iets misgegaan met je betaling. Probeer het later opnieuw. Order: ${id}`}
              icon={<FaTimes className="text-red-500" />}
            />
          )}

          {order.status === "expired" && (
            <Status title="Verlopen" description="Je betaling is verlopen. Probeer het opnieuw." icon={<FaClock />} />
          )}

          {order.status === "canceled" && (
            <Status title="Geannuleerd" description="De betaling is geannuleerd" icon={<FaTimes />} />
          )}
        </Card>
      </Page>
    )
  );
};
