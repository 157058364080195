import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { UploadContext } from "../../contexts/UploadContext";
import ModalUploadProcess from "../modals/ModalUploadProcess";
import Card from "./Card";
import React from "react";
import ModalCodesPhotos from "components/modals/ModalCodesPhotos";
import { Dropzone } from "components/forms/Dropzone";
import { DropzoneFileList } from "components/forms/DropzoneFileList";
import { ModalPrompt } from "components/modals/ModalPrompt";

const ChecklistItem = ({ text }) => {
  return (
    <div className="flex gap-2 font-semibold text-sm">
      <FontAwesomeIcon className="text-blue-500 mt-1" icon={faCheck} />
      <p className="text-slate-700">{text}</p>
    </div>
  );
};

export default function CardDashboardPhotos() {
  // @ts-ignore
  const { files, setFiles, upload, uploading, uploadResult, uploadingProgress, modalUploadAlert, setModalUploadAlert } =
    useContext(UploadContext);

  return (
    <Card className="md:col-start-1 md:col-end-4" title="Foto's uploaden">
      {modalUploadAlert.show && (
        <ModalPrompt
          title={modalUploadAlert.title}
          description={modalUploadAlert.message}
          onConfirm={() => setModalUploadAlert({ show: false, title: "", message: "" })}
          confirmText="Oké"
        />
      )}
      {uploading && <ModalUploadProcess files={files} uploadingProgress={uploadingProgress} />}
      {uploadResult.length !== 0 && <ModalCodesPhotos />}
      <div className="grid md:grid-cols-2 gap-8">
        <Dropzone files={files} setFiles={setFiles} />
        <div className="checklist_wrapper ">
          {files.length > 0 ? (
            <DropzoneFileList files={files} setFiles={setFiles} upload={upload} />
          ) : (
            <div className="flex flex-col gap-1 mt-2">
              <ChecklistItem text="Upload alleen JPG of PNG bestanden." />
              <ChecklistItem text="De bestandsgrootte mag maximaal 10 MB zijn." />
              <ChecklistItem text="Upload ook je gefotografeerde QR-codes. De foto's die na een gefotografeerde QR-code komen (en tot de volgende QR-code), worden aan die QR-code gekoppeld." />
              <ChecklistItem text="De foto's worden uitgelezen op alfabetische volgorde op basis van de bestandsnaam. Zorg dat ze dus op volgorde van bestandsnaam staan." />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
