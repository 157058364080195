import moment from "moment";
import React, { useContext, useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import Button from "components/forms/Button";
import { TableRow } from "components/layout/TableRow";
import Modal from "./Modal";
import firebase from "firebase";
import { DashboardContext } from "contexts/DashboardContext";
import { Tooltip } from "@chakra-ui/react";
import axios from "axios";
import { ModalPhoto } from "./ModalPhoto";
import { ModalPrompt } from "./ModalPrompt";
import { ModalCodeUpload } from "./ModalCodeUpload";

const Photo = ({ p, i, handleDeletePhoto, loadingDeletePhoto, setSelectedImage }) => {
  const [modalDeletePhoto, setModalDeletePhoto] = useState(false);

  return (
    <div className="bg-slate-200 items-center rounded-3xl mt-4 grid grid-cols-[96px,1fr]">
      {modalDeletePhoto && (
        <ModalPrompt
          title="Foto verwijderen"
          description={`Weet je zeker dat je foto ${p.url
            .split("/")
            .pop()} wilt verwijderen? Dit kan je niet ongedaan maken.`}
          onConfirm={() => handleDeletePhoto(p, i)}
          confirmText="Verwijderen"
          cancelText="Annuleren"
          loading={loadingDeletePhoto === i}
          close={() => setModalDeletePhoto(false)}
        />
      )}

      <Tooltip label="Klik om foto te openen" hasArrow>
        <button onClick={() => setSelectedImage(p.url)}>
          <img src={p.url} alt="" className="w-24 h-24 object-fit rounded-3xl bg-white" />
        </button>
      </Tooltip>
      <div className="flex justify-between w-full px-4">
        <p className="text-sm">{p.url.split("/").pop()}</p>
        <Tooltip hasArrow label="Foto verwijderen">
          <button
            onClick={() => loadingDeletePhoto !== i && setModalDeletePhoto(true)}
            className="bg-red-500 text-red-100 p-2 rounded-xl self-end hover:opacity-80"
          >
            {loadingDeletePhoto === i ? <FaSpinner className="icon-spin" /> : <FaTimes />}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default function ModalCode({ code, close }) {
  const hashmap = {};
  console.log(code.photos.forEach((p) => (hashmap[p.id] = hashmap[p.id] ? hashmap[p.id] + 1 : 1)));
  console.log(hashmap);
  const { sendToast, firebaseBaseUrl } = useContext(DashboardContext);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDeletePhoto, setLoadingDeletePhoto] = useState(-1);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleDeleteCode = async () => {
    setLoadingDelete(true);
    setModalDeleteCode(false);
    try {
      for (let i = 0; i < code.photos.length; i++) {
        await handleDeletePhoto(code.photos[i], -1, true, true);
      }
      await firebase.firestore().collection("codes").doc(code.id).delete();
      close();
      sendToast("Gelukt", "Code is verwijderd", "success");
    } catch (err) {
      sendToast("Niet gelukt", "Code niet verwijderd door een serverfout. Probeer het opnieuw", "error");
    }
    setLoadingDelete(false);
  };

  const handleDeletePhoto = async (photo, index, disableConfirm = false, disableToast = false) => {
    setLoadingDeletePhoto(index);
    try {
      // @ts-ignore
      const token = await firebase.auth().currentUser.getIdToken();
      await axios({
        method: "post",
        url: `${firebaseBaseUrl}deletePhoto`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        params: {
          photo: photo.id,
          code: code.id,
        },
      });
      if (!disableToast) sendToast("Gelukt", "Foto is verwijderd", "success");
    } catch (err) {
      sendToast("Niet gelukt", "Foto niet verwijderd door een serverfout. Probeer het opnieuw", "error");
    }
    setLoadingDeletePhoto(-1);
  };

  const [modalDeleteCode, setModalDeleteCode] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);

  return code == null ? null : (
    <Modal close={close}>
      {modalUpload && <ModalCodeUpload code={code.id} close={() => setModalUpload(false)} />}
      {modalDeleteCode && (
        <ModalPrompt
          title="Code verwijderen"
          description={`Weet je zeker dat je code ${code.id} wilt verwijderen? Dit kan je niet ongedaan maken.`}
          onConfirm={handleDeleteCode}
          confirmText="Verwijderen"
          cancelText="Annuleren"
          close={() => setModalDeleteCode(false)}
        />
      )}

      <div className="flex gap-4 flex-col">
        {selectedImage != null && <ModalPhoto close={() => setSelectedImage(null)} imageSrc={selectedImage} />}
        <h1 className="text-2xl font-semibold">{code.id}</h1>
        <table className="table-auto w-full text-sm md:text-base">
          <tbody>
            <TableRow rowKey="Aangemaakt" toolTip="Dag en tijdstip waarop je deze code gegenereerd hebt">
              <p>{code.createdAt.toDate && moment(code.createdAt.toDate()).format("dddd DD MMMM HH:mm")}</p>
            </TableRow>
            <TableRow rowKey="Geüpload" toolTip="De laatste keer dat je foto's aan deze code toegevoegd hebt">
              <p>
                {code.uploadedAt
                  ? code.createdAt.toDate && moment(code.uploadedAt.toDate() ?? 0).format("dddd DD MMMM HH:mm")
                  : `-`}
              </p>
            </TableRow>
            <TableRow rowKey="Gebruikt" toolTip="Geeft aan of de downloadpagina van deze code al eens bezocht is">
              {code.downloads.length === 0 ? (
                <p>Nee</p>
              ) : (
                <p className="bg-green-100 text-green-500 py-1 px-2 rounded-lg inline-block">
                  Ja (laatst op{" "}
                  {moment(code.downloads[code.downloads.length - 1].toDate() ?? 0).format("dddd D MMMM [om] HH:mm")})
                </p>
              )}
            </TableRow>
            <TableRow rowKey="URL">
              <input
                value={`mijnfotohoek.nl/code/${code.id}`}
                type="text"
                className="py-1 w-full px-4 border-2 border-slate-200 border-solid rounded-xl"
              />
            </TableRow>
            <TableRow rowKey="Verloopt op" toolTip="Vanaf deze datum kan de code niet meer gebruikt worden">
              <p>{code.expires ? moment(code.expires, "YYYY-MM-DD").format("dddd DD MMMM YYYY") : `niet`}</p>
            </TableRow>
          </tbody>
        </table>
        <div className="flex md:flex-row flex-col gap-4 justify-between">
          <Button onClick={() => setModalUpload(true)}>Foto's toevoegen</Button>
          <Button loading={loadingDelete} onClick={() => setModalDeleteCode(true)} color="bg-red-100 text-red-500">
            Code verwijderen
          </Button>
        </div>

        {code.expires && moment(code.expires, "YYYY-MM-DD").isBefore(moment()) ? (
          <div className="w-full bg-orange-100 text-orange-500 p-4 rounded-xl">
            <h1 className="text-lg font-bold">
              Deze code is verlopen op {moment(code.expires, "YYYY-MM-DD").format("dddd DD MMMM YYYY")}
            </h1>
            <p>De code kan niet meer gebruikt worden. De foto's worden binnenkort verwijderd.</p>
          </div>
        ) : (
          <div>
            <h1 className="text-2xl font-semibold">Foto's</h1>
            {code.photos.length === 0 && (
              <p className="text-gray-500">Je hebt nog geen foto's toegevoegd aan deze code</p>
            )}
            {code.photos.map((p, i) => (
              <Photo
                loadingDeletePhoto={loadingDeletePhoto}
                setSelectedImage={setSelectedImage}
                handleDeletePhoto={handleDeletePhoto}
                p={p}
                i={i}
                key={`photo${p.id}`}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}
