import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const TableRow = ({ label, value }) => {
  return (
    <tr>
      <td className="pr-8 font-bold">{label}</td>
      <td>{value}</td>
    </tr>
  );
};

export const Invoice = () => {
  const loc = useLocation();
  const [params] = useState(new URLSearchParams(loc.search));
  // @ts-ignore
  const { id, token } = useParams();
  const width = 21;
  const height = 29.7;

  const price = parseInt(params.get("price"));

  return (
    <div className="bg-white p-16 flex flex-col gap-10" style={{ width: `${width}cm`, height: `${height}cm` }}>
      <h1 className="font-semibold text-2xl">mijnfotohoek.nl</h1>
      <div>
        <table className="">
          <TableRow label="Gebruikersnaam" value={params.get("user")} />
          <TableRow label="Datum" value={params.get("date")} />
          <TableRow label="Factuur" value={params.get("paymentId")} />
        </table>
      </div>
      <div className="flex justify-between">
        <h1 className="font-semibold text-4xl">Factuur</h1>
      </div>
      <div>
        <table className="w-full text-lg">
          <tbody>
            <tr className="border-b-2 border-solid border-slate-400">
              <td className="font-bold py-2">Abonnement mijnfotohoek.nl</td>
              <td className="text-right">€ {Math.round((price / 1.21) * 100) / 100}</td>
            </tr>
            <tr className="">
              <td className=" py-2">Subtotaal</td>
              <td className="text-right">€ {Math.round((price / 1.21) * 100) / 100}</td>
            </tr>
            <tr className="border-b-2 border-solid border-slate-400">
              <td className="py-2">BTW (21%)</td>
              <td className="text-right">€ {Math.round((price / 1.21) * 0.21 * 100) / 100}</td>
            </tr>
            <tr className="">
              <td className="font-bold py-2">Totaal</td>
              <td className="text-right">€ {price}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="mt-auto text-center font-bold">mijnfotohoek.nl - kvk: 72163216</p>
    </div>
  );
};
