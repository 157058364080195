import React from "react";
import { FaAngleRight } from "react-icons/fa";
import Instruction from "./Instruction";
import "moment/locale/nl";
import { Printer } from "components/icons/Printer";
import { CameraQRCode } from "components/icons/CameraQRCode";
import { Hand } from "components/icons/Hand";
import { Laptop } from "components/icons/Laptop";

export default function PrintInstructions({ codes }: { codes: Array<string> }) {
  return (
    <div style={{ width: `21cm`, height: `29.7cm` }} className={`py-20 px-10 bg-white flex flex-col gap-5`}>
      <div className={`w-full text-slate-400 text-2xl`}>
        <p className="flex gap-2 justify-center items-center">
          <FaAngleRight /> <FaAngleRight /> QR-codes vind je op pagina 2 <FaAngleRight /> <FaAngleRight />
        </p>
      </div>

      <div>
        <h1 className="text-slate-600 text-3xl">mijnfotohoek.nl</h1>
        <p className=" text-gray-400 text-xl">
          {codes.length} downloadcode{codes.length !== 1 ? `s` : ``}
        </p>
      </div>

      <div className="grid-cols-2 grid gap-4 max-w-xl">
        <Instruction
          title="1. Printen"
          icon={<Printer />}
          instruction={`Print dit bestand uit. Elke QR-code in dit bestand bevat een unieke downloadlink.`}
        />
        <Instruction
          title="2. Fotograferen"
          icon={<CameraQRCode />}
          instruction={`Zodra je iemand op de foto gaat zetten in je fotohoek, maak je eerst een foto van één van de unieke QR-codes. Zet de persoon daarna op de foto.`}
        />
        <Instruction
          title="3. Uitdelen"
          icon={<Hand />}
          instruction={`Geef de QR-code die je net gefotografeerd hebt mee aan de gefotografeerde persoon. Herhaal stap 2 en 3 zolang je aan het fotograferen bent.`}
        />
        <Instruction
          title="4. Uploaden"
          icon={<Laptop />}
          instruction={`Zet je foto's op je computer en upload ze (inclusief de foto's van de QR-codes) via het dashboard. De foto's die na een gefotografeerde QR-code komen, worden aan die QR-code gekoppeld. Zorg dat de foto's dus op volgorde staan (op basis van bestandsnaam).`}
        />
      </div>
    </div>
  );
}
