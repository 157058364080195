import React from "react";
import Button from "../forms/Button";
import Modal from "./Modal";

export const ModalPhoto = ({ imageSrc, close }) => {
  return (
    <Modal width={800} close={close}>
      <div className="flex gap-6 flex-col">
        <h1 className="text-center text-3xl pr-8">{imageSrc.split("/").pop()}</h1>
        <img src={imageSrc} alt="" className="max-h-96 block mx-auto" />
        <div className="mx-auto">
          <a href={imageSrc} download>
            <Button>Foto downloaden</Button>
          </a>
        </div>
      </div>
    </Modal>
  );
};
