import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import { Login } from "./pages/Login";
import "./css/app.scss";
import ScrollToTop from "./utils/ScrollToTop";
import "./css/index.css";
import { NotFound } from "./pages/NotFound";
import React from "react";
import DashboardContextProvider from "contexts/DashboardContext";
import { CodesPrintPage } from "components/print/CodesPrintPage";
import { Register } from "pages/Register";
import { Reset } from "pages/Reset";
import { Invoice } from "pages/Invoice";
import { VerifyEmailPlaceholder } from "pages/user_management/VerifyEmailPlaceholder";
import { UserManagement } from "pages/user_management/UserManagement";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/dashboard/factuur" component={Invoice} />
          <Route path="/download/:id" component={CodesPrintPage} />

          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/reset" component={Reset} />
          <Route exact path="/verifieer" component={VerifyEmailPlaceholder} />
          <Route exact path="/gebruiker" component={UserManagement} />
          <Route path="/dashboard/">
            <DashboardContextProvider>
              <Dashboard />
            </DashboardContextProvider>
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
