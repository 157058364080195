import React from "react";
import Header from "./Header";
import { motion } from "framer-motion";

export default function Page({ children }) {
  return (
    <div className="max-w-6xl mx-auto py-0 sm:p-5 md:p-10 pt-10 pb-10 text-gray-800">
      <motion.div initial="initial" animate="animate">
        <Header />
        {children}
      </motion.div>
    </div>
  );
}
