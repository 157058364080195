import Button from "components/forms/Button";
import { Input } from "components/forms/Input";
import { PageAuth } from "components/layout/PageAuth";
import { Form, Formik } from "formik";
import firebase from "../firebase";
import React from "react";
import { object, string } from "yup";
import { Link } from "react-router-dom";

export const Reset = () => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState("");

  const handleReset = async (values: { email: string }, actions) => {
    setError("");
    setSuccess("");
    setLoading(true);
    try {
      await firebase.auth().sendPasswordResetEmail(values.email);
      setSuccess(
        "Er is een email verstuurd naar het opgegeven emailadres. Volg de instructies in de email om je wachtwoord te resetten."
      );
    } catch (err: any) {
      if (err.code === "auth/user-not-found") {
        actions.setFieldError("email", "Er is geen account met dit emailadres");
      } else {
        setError(`Er is iets misgegaan. Probeer het later opnieuw. (${err.code})`);
      }
    }
    setLoading(false);
  };

  return (
    <PageAuth>
      <div className="max-w-md ">
        <h1 className=" text-xl mt-3 mb-3 text-white">Wachtwoord resetten</h1>
        <div className="mt-10">
          <p className="text-slate-500 mb-8">
            Vul je emailadres in. Je ontvangt dan instructies voor het resetten van je wachtwoord.
          </p>
          <Formik
            validationSchema={object({
              email: string().email("Onjuist e-mailadres").required("Vereist"),
            })}
            onSubmit={(values, actions) => handleReset(values, actions)}
            initialValues={{ email: "", password: "", passwordConfirm: "" }}
          >
            <Form>
              <div className="flex gap-4 flex-col">
                {error !== "" && <p className="text-red-500 font-bold">{error}</p>}
                {success !== "" && <p className="text-green-500 font-bold">{success}</p>}
                <Input optional={false} label="Emailadres" name="email" />
                <Button type="submit" className="pt-1 pb-1" size="text-lg" loading={loading}>
                  Resetten
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="mt-10 text-blue-500">
          <Link to="/register">Account aanmaken</Link>
        </div>
        <div className="mt-2 text-blue-500">
          <Link to="/">Inloggen</Link>
        </div>
      </div>
    </PageAuth>
  );
};
