import React from "react";
import { useField } from "formik";
import { InputError } from "./InputError";

interface Props {
  label?: string;
  optional?: Boolean;
  error?: string;
  className?: string;
  classNameError?: string;
  inputErrorAbsolute?: Boolean;
  options?: Array<{ label: string; value: string }>;
  name?: string;
  type?: string;
  disabled?: boolean;
}

export const Input = ({
  label,
  optional = false,
  className = "",
  error,
  disabled = false,
  classNameError = "",
  inputErrorAbsolute = false,
  options,
  ...props
}: Props) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  return (
    <div className="relative">
      {label != null && (
        <p className="font-bold mb-2 text-slate-400">
          {label} {optional && <span className="text-xs text-slate-300">(optional)</span>}
        </p>
      )}
      {options == null ? (
        props.type === "textarea" ? (
          <textarea
            {...field}
            {...props}
            className={`w-full border-slate-300 rounded-xl p-2 resize-none h-96 border-2 focus:outline-none focus:border-slate-500 ${className}`}
          />
        ) : (
          <input
            {...field}
            disabled={disabled}
            {...props}
            className={`focus:border-blue-500 outline-none border-2 rounded-2xl py-2 block w-full px-4 ${className} ${
              disabled && `cursor-not-allowed`
            }`}
          />
        )
      ) : (
        <select
          {...field}
          {...props}
          className={`focus:border-blue-500 outline-none  border-2 rounded-xl py-2 px-4 ${className} ${
            meta.touched && meta.error && `border-red-200`
          }`}
        >
          <option value="" selected disabled hidden>
            Choose
          </option>
          {options.map((o, i) => (
            <option key={i} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      )}
      {meta.touched && meta.error && (
        <InputError absolute={inputErrorAbsolute} className={classNameError}>
          {meta.error}
        </InputError>
      )}
    </div>
  );
};
