import axios from "axios";
import { Dropzone } from "components/forms/Dropzone";
import { DropzoneFileList } from "components/forms/DropzoneFileList";
import { DashboardContext } from "contexts/DashboardContext";
import React from "react";
import Modal from "./Modal";
import ModalUploadProcess from "./ModalUploadProcess";
import firebase from "../../firebase";

export const ModalCodeUpload = ({ close, code }) => {
  const [files, setFiles] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [uploadingProgress, setUploadingProgress] = React.useState(0);
  const { firebaseBaseUrl, sendToast } = React.useContext(DashboardContext);

  const upload = async () => {
    setUploading(true);
    setUploadingProgress(0);
    let error = false;
    const token = await firebase.auth().currentUser.getIdToken(true);
    for (let i = 0; i < files.length; i++) {
      setUploadingProgress(i + 1);
      try {
        // make request to upload endpoint; returns corresponding firestore documents
        await axios({
          url: `${firebaseBaseUrl}uploadToCode`,
          data: files[i],
          method: "post",
          params: { code, fileName: files[i].name },
          headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
        });
      } catch (err) {
        error = true;
        // internal server error
        sendToast(`${files[i].name} niet geüpload`, `Dit bestand is niet geüpload door een server fout`, "error", null);
      }
    }
    if (!error) sendToast("Gelukt", `Foto${files.length === 1 ? ` is` : `'s zijn`} geüpload`, "success");
    setUploading(false);
    setUploadingProgress(0);
    close();
  };

  return (
    <Modal
      title="Uploaden"
      width={600}
      close={close}
      description="Upload hier foto's die je wilt toevoegen aan deze code. Je kunt meerdere foto's tegelijk uploaden. Het is niet nodig om een foto van de QR code toe te voegen."
    >
      {uploading && <ModalUploadProcess files={files} uploadingProgress={uploadingProgress} />}
      <div className="flex flex-col gap-8 mt-8">
        <Dropzone files={files} setFiles={setFiles} />
        {files.length > 0 && (
          <DropzoneFileList files={files} setFiles={setFiles} upload={upload} uploading={uploading} />
        )}
      </div>
    </Modal>
  );
};
