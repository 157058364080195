import React, { useEffect, useState } from "react";
import { FaClock, FaInfoCircle } from "react-icons/fa";
import firebase from "../firebase";

interface Error {
  content: string;
  header: string;
  enabled: Boolean;
  resolve_estimate: string;
}

export const DashboardErrorMessage = () => {
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    getError();
  }, []);
  const getError = async () => {
    const docQuery = await firebase.firestore().collection("settings").doc("dashboard_error").get();
    if (!docQuery.exists) return;
    const doc = docQuery.data() as Error;
    setError(doc);
  };
  return !error || !error.enabled ? null : (
    <div className="w-full bg-red-50 z-10 relative p-6 rounded-xl text-red-500  col-start-1 md:col-end-3 col-end-4">
      <p className="text-xl font-bold">
        <FaInfoCircle className="inline-block" /> {error.header}
      </p>
      <p>{error.content}</p>
      <p className="mt-2">
        <FaClock className="inline-block" /> {error.resolve_estimate}
      </p>
    </div>
  );
};
