import axios from "axios";
import { DashboardContext } from "contexts/DashboardContext";
import React, { useContext, useEffect, useState } from "react";
import { CodesHistory } from "@mijnfotohoek-types";
import PrintCode from "./PrintCode";
import PrintInstructions from "./PrintInstructions";
import "css/printpage.scss";

export const CodesPrintPage = ({
  match: {
    params: { id },
  },
}) => {
  const [data, setData] = useState<CodesHistory | null>(null);
  const { firebaseBaseUrl } = useContext(DashboardContext);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = async () => {
    const { data }: { data: CodesHistory } = await axios({
      method: "post",
      url: `${firebaseBaseUrl}getCodesHistory`,
      params: {
        id,
      },
    });
    setData(data);
  };
  return data === null ? null : (
    <div>
      {data.downloadPreferences.instructionPage === "visible" && <PrintInstructions codes={data.codes} />}
      <div className={`grid grid-cols-1`} style={{ maxWidth: `21cm` }}>
        {data.codes.map((c, i) => (
          <PrintCode
            email={data.downloadPreferences.email}
            period={data.downloadPreferences.desc}
            language={data.downloadPreferences.language}
            expires={data.expires}
            key={i}
            code={c}
          />
        ))}
      </div>
    </div>
  );
};
