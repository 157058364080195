import { CircularProgress } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

const RevealDelay = styled.div`
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
`;

export default function Loading() {
  return (
    <RevealDelay className="text-blue-500 text-center mt-20 text-4xl">
      <h1 className="mb-4 font-semibold">mijnfotohoek.nl</h1>
      <CircularProgress capIsRound isIndeterminate color="#3b82f6" />
    </RevealDelay>
  );
}
