import React, { useContext, useEffect, useState } from "react";
import Card from "./Card";
import firebase from "../../firebase";
import { DashboardContext } from "../../contexts/DashboardContext";
import Button from "components/forms/Button";
import { ModalCodeSearch } from "components/modals/ModalCodeSearch";
import { Code } from "components/layout/Code";
/*

field toevoegen met "last upload", daarop sorteren zodat de laatste bovenaan staan.
aan het user doc een field toevoegen "uploads" met verwijzingen naar upload documents.
deze uploads via de modal kunnen selecteren


*/

export default function CardDashboardHistory() {
  // @ts-ignore
  const { user } = useContext(DashboardContext);
  const [data, setData] = useState([]);
  const [limitIncrease] = useState(5);
  const [limit, setLimit] = useState(limitIncrease);
  const [increaseLimitButton, setIncreaseLimitButton] = useState(true);
  useEffect(() => {
    if (user != null) {
      const unsubscribe = firebase
        .firestore()
        .collection("codes")
        .where("user", "==", user)
        .orderBy("uploadedAt", "desc")
        .limit(limit)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
          if (docs.length < limit) setIncreaseLimitButton(false);

          setData(docs);
        });
      return () => unsubscribe();
    }
  }, [user, limit]);

  const [modalCodeSearch, setModalCodeSearch] = useState(false);

  return (
    <Card className="md:col-start-1 md:col-end-4" title={`Jouw foto's`}>
      {modalCodeSearch && <ModalCodeSearch close={() => setModalCodeSearch(false)} />}
      <div>
        <Button onClick={() => setModalCodeSearch(true)}>Vind een code</Button>
      </div>
      {data.length === 0 && <p>Je hebt nog geen foto's geüpload</p>}
      <div className="flex flex-col gap-4">
        {data.map((d) => (
          <Code code={d} key={d.id} />
        ))}
      </div>
      {increaseLimitButton && (
        <div>
          <Button onClick={() => setLimit(limit + limitIncrease)} className="mx-auto block" color="text-blue-500">
            Meer codes laden
          </Button>
        </div>
      )}
      {!increaseLimitButton && limit > limitIncrease && (
        <div className="text-center font-semibold text-slate-500">
          <p className="">Geen codes meer om te laden.</p>
          <p className="text-xs">
            Codes zonder foto's worden niet weergegeven in deze lijst. Gebruik daarvoor de "Vind een code"-knop.
          </p>
        </div>
      )}
    </Card>
  );
}
