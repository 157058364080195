import React from "react";
import Modal from "./Modal";
import { CircularProgress } from "@chakra-ui/react";
import moment from "moment";

export default function ModalUploadProcess({ uploadingProgress, files }) {
  return (
    <Modal>
      <div className="flex items-center gap-4 flex-col">
        <h1 className="text-3xl">Aan het uploaden...</h1>
        <CircularProgress isIndeterminate capIsRound={true} color="#3b82f6" size={200} thickness={4} />
        <div>
          <p className="text-slate-600 text-lg text-center">
            Foto {uploadingProgress} van {files.length} aan het uploaden
          </p>
          <p className="text-slate-400 text-center">
            (nog ongeveer {moment.duration(-(files.length - uploadingProgress) * 10, "seconds").humanize()})
          </p>
        </div>
      </div>
    </Modal>
  );
}
