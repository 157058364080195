import React from "react";

export default function Instruction({ title, icon, instruction }) {
  return (
    <div>
      <div className="h-24 flex items-center">{icon}</div>
      <h1 className="text-slate-600 text-3xl">{title}</h1>
      <p className="text-slate-400 text-lg">{instruction}</p>
    </div>
  );
}
