import React from "react";

export const Printer = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="52.86"
      height="52.829"
      viewBox="0 0 52.86 52.829"
    >
      <g id="printer">
        <path
          id="Path_54"
          data-name="Path 54"
          d="M45.78,15.78H10.57c-3.25,0-8.81,4.48-8.81,9.51s2,24,2,24H52.62s2-18.91,2-24S49,15.78,45.78,15.78Z"
          transform="translate(-1.76 -3.45)"
          fill="#e8e8e8"
        />
        <path
          id="Path_55"
          data-name="Path 55"
          d="M6.16,49.24v3.52a1.77,1.77,0,0,0,1.76,1.76H48.43a1.77,1.77,0,0,0,1.76-1.76V49.24Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <path
          id="Path_56"
          data-name="Path 56"
          d="M42.85,15.78H13.5c-2.7,0-7.34,4.48-7.34,9.51s1.65,24,1.65,24H48.54s1.65-18.91,1.65-24S45.55,15.78,42.85,15.78Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <path
          id="Path_57"
          data-name="Path 57"
          d="M45.78,45.72c0,1.93-.39,3.52-.88,3.52H11.45c-.49,0-.88-1.59-.88-3.52s.39-2.65.88-2.65H44.9C45.39,43.07,45.78,43.78,45.78,45.72Z"
          transform="translate(-1.76 -3.45)"
          fill="#62727a"
        />
        <path
          id="Path_58"
          data-name="Path 58"
          d="M45.67,52.8a32.24,32.24,0,0,1-4.4-7.08H15.08a32.24,32.24,0,0,1-4.4,7.08c-3.29,4.26,2.31,3.41,6.05,3.41H39.62C43.36,56.21,49,57.06,45.67,52.8Z"
          transform="translate(-1.76 -3.45)"
          fill="#e8e8e8"
        />
        <path
          id="Path_59"
          data-name="Path 59"
          d="M17.52,47.76a37.35,37.35,0,0,1-3.37,6.17c-.28.44.46.84.73.4A38.09,38.09,0,0,0,18.34,48C18.53,47.49,17.7,47.29,17.52,47.76Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <path
          id="Path_60"
          data-name="Path 60"
          d="M22.64,47.76A40.7,40.7,0,0,1,20.93,54c-.16.48.67.69.83.21A41.87,41.87,0,0,0,23.47,48C23.54,47.47,22.72,47.26,22.64,47.76Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <path
          id="Path_61"
          data-name="Path 61"
          d="M32.89,48a41.83,41.83,0,0,0,1.7,6.27c.16.48,1,.27.83-.21a40.7,40.7,0,0,1-1.71-6.27C33.63,47.26,32.81,47.47,32.89,48Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <path
          id="Path_62"
          data-name="Path 62"
          d="M38,48a38.78,38.78,0,0,0,3.45,6.36c.28.44,1,0,.74-.4a37.351,37.351,0,0,1-3.37-6.17C38.65,47.29,37.82,47.49,38,48Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <path
          id="Path_63"
          data-name="Path 63"
          d="M27.75,47.86v6.27a.43.43,0,0,0,.85,0V47.86C28.6,47.36,27.75,47.36,27.75,47.86Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <path
          id="Path_64"
          data-name="Path 64"
          d="M14.86,17.54a4.71,4.71,0,0,0-2.09,4c0,.85,0,1.32,5.14,1.32H38.44c5.14,0,5.14-.47,5.14-1.32a4.71,4.71,0,0,0-2.09-4Z"
          transform="translate(-1.76 -3.45)"
          fill="#62727a"
        />
        <path
          id="Path_65"
          data-name="Path 65"
          d="M39.62,3.45H16.73A1.76,1.76,0,0,0,15,5.21V19.3H41.38V5.21a1.76,1.76,0,0,0-1.76-1.76Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <path
          id="Path_66"
          data-name="Path 66"
          d="M38.74,5.21H17.61a.89.89,0,0,0-.88.88V19.3H39.62V6.09a.89.89,0,0,0-.88-.88Z"
          transform="translate(-1.76 -3.45)"
          fill="#e8e8e8"
        />
        <path
          id="Path_67"
          data-name="Path 67"
          d="M28.18,8.74a7,7,0,0,1-5.69-2.9,6.17,6.17,0,0,0,11.37,0,7,7,0,0,1-5.68,2.9Z"
          transform="translate(-1.76 -3.45)"
          fill="#b2c1c0"
        />
        <rect
          id="Rectangle_726"
          data-name="Rectangle 726"
          width="19.37"
          height="9.69"
          transform="translate(16.73 6.16)"
          fill="#fff"
        />
        <path
          id="Path_68"
          data-name="Path 68"
          d="M46.66,25.46c0,.49,0,.88-18.48.88S9.69,26,9.69,25.46s0-.88,18.49-.88S46.66,25,46.66,25.46Z"
          transform="translate(-1.76 -3.45)"
          fill="#e8e8e8"
        />
        <path
          id="Path_69"
          data-name="Path 69"
          d="M44,30.75a.88.88,0,1,1-.88.88A.88.88,0,0,1,44,30.75Z"
          transform="translate(-1.76 -3.45)"
          fill="#f15744"
        />
        <path
          id="Path_70"
          data-name="Path 70"
          d="M44,34.27a.88.88,0,1,1-.88.88.87.87,0,0,1,.88-.88Z"
          transform="translate(-1.76 -3.45)"
          fill="#c7e755"
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="M36.9,45.72H19.45a16.54,16.54,0,0,1-2.72,7H39.62A16.54,16.54,0,0,1,36.9,45.72Z"
          transform="translate(-1.76 -3.45)"
          fill="#fff"
        />
        <rect
          id="Rectangle_727"
          data-name="Rectangle 727"
          width="7.92"
          height="5.28"
          rx="0.88"
          transform="translate(8.8 27.29)"
          fill="#c7e755"
        />
        <path
          id="Path_72"
          data-name="Path 72"
          d="M11.45,38.23h1.76a.85.85,0,0,0,.74-.44.85.85,0,0,1,.14.44.88.88,0,0,1-.88.88H11.45a.87.87,0,0,1-.88-.88.84.84,0,0,1,.13-.44.88.88,0,0,0,.75.44Z"
          transform="translate(-1.76 -3.45)"
          fill="#62727a"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M15.85,38.23h1.76a.85.85,0,0,0,.74-.44.85.85,0,0,1,.14.44.88.88,0,0,1-.88.88H15.85a.87.87,0,0,1-.88-.88.94.94,0,0,1,.13-.44A.88.88,0,0,0,15.85,38.23Z"
          transform="translate(-1.76 -3.45)"
          fill="#62727a"
        />
      </g>
    </svg>
  );
};
