import React, { useContext, useEffect, useState } from "react";
import Image from "./components/Image";
import Modal from "./Modal";
import Button from "components/forms/Button";
import { UploadContext } from "contexts/UploadContext";
import ModalCodesManual from "./ModalCodesManual";
import { DashboardContext } from "contexts/DashboardContext";
import axios from "axios";
import firebase from "../../firebase";

export default function ModalCodesPhotos() {
  const { setUploadResult, uploadResult, resetUpload, setModalUploadAlert } = useContext(UploadContext);
  const { firebaseBaseUrl, sendToast } = useContext(DashboardContext);
  const [selected, setSelected] = useState<number>(-1);
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    let c = 0;
    uploadResult.forEach((photo) => {
      if (photo.valueQR) c++;
    });
    if (c === 0) {
      setModalUploadAlert({
        show: true,
        title: "Let op: Geen QR codes gevonden",
        message:
          "Ons systeem heeft geen QR-codes gedetecteerd in de foto's die je hebt geupload. Het kan zijn dat een QR-code overgeslagen is. Controleer dit en vul deze QR-code handmatig in. Voor meer uitleg over het uploaden van foto's, zie de support-pagina.",
      });
    }
  }, []);

  const handleFinish = async () => {
    setLoading(true);

    let result = {};
    let currentCode = null;

    uploadResult.forEach((d) => {
      if (d.valueQR === null && currentCode === null) {
        if (result["null"] === undefined) result["null"] = [];
      }
      if (d.valueQR !== null && d.valueQR != "") {
        currentCode = d.valueQR;
        if (result[`${currentCode}`] === undefined) result[`${currentCode}`] = [];
      } else {
        result[`${currentCode}`].push(d.id);
      }
    });
    const token = await firebase.auth().currentUser.getIdToken(true);
    try {
      await axios({
        method: "POST",
        url: `${firebaseBaseUrl}matchCodes`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(result),
      });
      setLoading(false);
      sendToast(
        "Gelukt",
        "Foto's zijn geüpload en gekoppeld aan de QR-codes. Ze kunnen nu gedownload worden!",
        "success"
      );
      resetUpload();
    } catch (err) {
      setLoading(false);
      console.log(err);
      sendToast("Niet gelukt", "Uploaden afronden niet gelukt door serverfout", "error");
    }
  };
  return (
    <Modal
      title="Controleer of alle QR-codes uitgelezen zijn."
      description={`Staat er een overgeslagen QR-code tussen? Klik dan op het QR-code icoontje om de downloadcode handmatig in te vullen. Klik vervolgens, als je alles gecontroleerd hebt, op de "Uploaden afronden"-knop. Je kan de volgorde van de foto's aanpassen door de foto's te slepen.`}
    >
      {selected !== -1 && (
        <ModalCodesManual
          selected={selected}
          setSelected={setSelected}
          uploadResult={uploadResult}
          setUploadResult={setUploadResult}
        />
      )}
      <div className="max-h-96 md:h-96 overflow-y-auto">
        <div className="flex flex-wrap mt-5 gap-y-3">
          {uploadResult.map((d, i) => (
            <Image index={i} setSelected={setSelected} src={d.url_thumb} qr={d.valueQR === null ? `` : d.valueQR} />
          ))}
        </div>
      </div>
      <div className="flex justify-between items-center mt-10">
        <Button className="bg-white" onClick={resetUpload}>
          <span className="hidden sm:block">Uploaden</span> annuleren
        </Button>
        <Button onClick={handleFinish} loading={loading}>
          <span className="hidden sm:block">Uploaden</span> afronden
        </Button>
      </div>
    </Modal>
  );
}
