import React from "react";

export const CameraQRCode = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="84.393"
      height="75.893"
      viewBox="0 0 84.393 75.893"
    >
      <g id="Layer_1" data-name="Layer 1">
        <g id="Group_644" data-name="Group 644">
          <g id="Group_641" data-name="Group 641">
            <rect
              id="Rectangle_695"
              data-name="Rectangle 695"
              width="55.01"
              height="47.01"
              rx="9"
              transform="translate(24.74 63.718) rotate(-74.99)"
              fill="#eee"
            />
            <path
              id="solid_qrcode"
              data-name="solid qrcode"
              d="M45.68,39.49l11.1,3,3-11.1-11.09-3Zm5.68-6.4,3.7,1-1,3.7-3.7-1Zm12.09-.72-3,11.09,11.1,3,3-11.1Zm5.41,9.37-3.7-1,1-3.69,3.7,1ZM41.71,54.29l11.1,3,3-11.09-11.09-3Zm5.69-6.41,3.69,1-1,3.69-3.7-1Zm21.33,1.76,1.85.5-2,7.39L63.05,56l.49-1.85-1.85-.49-1.48,5.55-3.7-1,3-11.09L65,48.65l-.5,1.84,3.7,1Zm-2.48,9.25,1.85.49-.5,1.85-1.85-.5Zm-3.7-1,1.85.5-.5,1.85-1.85-.5Z"
              transform="translate(-3.63 -1.69)"
              fill="#8e8e8e"
            />
          </g>
        </g>
        <rect
          id="Rectangle_721"
          data-name="Rectangle 721"
          width="10.75"
          height="5.42"
          rx="1.67"
          transform="translate(5.8 19.17)"
          fill="#212528"
        />
        <rect
          id="Rectangle_722"
          data-name="Rectangle 722"
          width="5.74"
          height="2.89"
          rx="0.89"
          transform="translate(40.79 20.78)"
          fill="#212528"
        />
        <path
          id="Path_21"
          data-name="Path 21"
          d="M52.72,50.9a4.18,4.18,0,0,1-4.18,4.17H7.81A4.18,4.18,0,0,1,3.63,50.9V49.65H52.72Z"
          transform="translate(-3.63 -1.69)"
          fill="#212528"
        />
        <path
          id="Path_22"
          data-name="Path 22"
          d="M3.63,27.74A4.17,4.17,0,0,1,7.8,23.57H48.54a4.18,4.18,0,0,1,4.18,4.17V29H3.63Z"
          transform="translate(-3.63 -1.69)"
          fill="#51575b"
        />
        <rect
          id="Rectangle_723"
          data-name="Rectangle 723"
          width="49.08"
          height="21.69"
          transform="translate(0 27.1)"
          fill="#3e4347"
        />
        <path
          id="Path_23"
          data-name="Path 23"
          d="M45.55,27H21l2.47-13.45a4.65,4.65,0,0,1,1.84-2.25c3.75-1.67,12.23-1.67,16,0a4.65,4.65,0,0,1,1.84,2.25Z"
          transform="translate(-3.63 -1.69)"
          fill="#51575b"
        />
        <path
          id="Path_24"
          data-name="Path 24"
          d="M44.36,35H22.14l2.23-11.23A4,4,0,0,1,26,21.91c3.38-1.39,11-1.39,14.42,0a4,4,0,0,1,1.67,1.87Z"
          transform="translate(-3.63 -1.69)"
          fill="#3e4347"
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M50.12,41.17A16.87,16.87,0,1,1,33.25,24.3,16.87,16.87,0,0,1,50.12,41.17Z"
          transform="translate(-3.63 -1.69)"
          fill="#788287"
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M48.25,41.17a15,15,0,1,1-15-15A15,15,0,0,1,48.25,41.17Z"
          transform="translate(-3.63 -1.69)"
          fill="#212528"
        />
        <circle
          id="Ellipse_127"
          data-name="Ellipse 127"
          cx="12.19"
          cy="12.19"
          r="12.19"
          transform="translate(17.43 27.29)"
          fill="#3e4347"
        />
        <circle
          id="Ellipse_128"
          data-name="Ellipse 128"
          cx="6.56"
          cy="6.56"
          r="6.56"
          transform="translate(23.06 32.92)"
          fill="#212528"
        />
        <g id="Group_644-2" data-name="Group 644" opacity="0.5">
          <path
            id="Path_27"
            data-name="Path 27"
            d="M42.22,36a3.77,3.77,0,1,1-1.1-2.676A3.78,3.78,0,0,1,42.22,36Z"
            transform="translate(-3.63 -1.69)"
            fill="#f5f5f5"
          />
        </g>
        <g id="Group_645" data-name="Group 645" opacity="0.5">
          <circle
            id="Ellipse_129"
            data-name="Ellipse 129"
            cx="2.34"
            cy="2.34"
            r="2.34"
            transform="translate(24.94 39.48)"
            fill="#f5f5f5"
          />
        </g>
        <g id="Group_646" data-name="Group 646" opacity="0.5">
          <path
            id="Path_28"
            data-name="Path 28"
            d="M26.69,46.28a1.45,1.45,0,1,1-1.45,1.45,1.45,1.45,0,0,1,1.45-1.45Z"
            transform="translate(-3.63 -1.69)"
            fill="#f5f5f5"
          />
        </g>
        <path
          id="Path_29"
          data-name="Path 29"
          d="M15.14,47.07a1.67,1.67,0,0,1-1.67,1.67H6.38a1.67,1.67,0,0,1-1.67-1.67V32.19a1.66,1.66,0,0,1,1.67-1.66h7.09a1.66,1.66,0,0,1,1.67,1.66Z"
          transform="translate(-3.63 -1.69)"
          fill="#636c72"
        />
        <circle
          id="Ellipse_130"
          data-name="Ellipse 130"
          cx="2.43"
          cy="2.43"
          r="2.43"
          transform="translate(5.32 22.44)"
          fill="#212528"
        />
        <rect
          id="Rectangle_724"
          data-name="Rectangle 724"
          width="14.57"
          height="4.65"
          rx="1.53"
          transform="translate(22.33 12.23)"
          fill="#fff"
        />
        <path id="Path_30" data-name="Path 30" d="M33.81,13.34,44.37,6.4,42.43,3.9Z" fill="#ffce31" />
        <path id="Path_31" data-name="Path 31" d="M46.14,16.61v-3L35.28,15.7Z" fill="#ffce31" />
        <path id="Path_32" data-name="Path 32" d="M36.11,0H32.47L30.53,12.72Z" fill="#ffce31" />
        <path id="Path_33" data-name="Path 33" d="M25.98,13.34,16.94,3.9,14.92,6.4Z" fill="#ffce31" />
        <path id="Path_34" data-name="Path 34" d="M26.2,0H22.52l6.04,12.72Z" fill="#ffce31" />
        <path id="Path_35" data-name="Path 35" d="M12.84,13.61v3l11.32-.91Z" fill="#ffce31" />
      </g>
    </svg>
  );
};
