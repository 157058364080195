import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import Loading from "./Loading";
import { PageSideBarLayout } from "./PageSideBarLayout";

export const PageAuth = ({ children, title }: { children: React.ReactNode; title?: string }) => {
  const history = useHistory();
  const [loadingFirebase, setLoadingFirebase] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setLoadingFirebase(false);
      if (user !== null && user.emailVerified) history.push("/dashboard");
      if (user !== null && !user.emailVerified) history.push("/verifieer");
    });
  }, [history]);

  return loadingFirebase ? <Loading /> : <PageSideBarLayout title={title}>{children}</PageSideBarLayout>;
};
