import { Tooltip } from "@chakra-ui/react";
import ModalCode from "components/modals/ModalCode";
import React from "react";
import { useState } from "react";
import { FaInfo, FaUpload } from "react-icons/fa";
import { Code as CodeType } from "@mijnfotohoek-types";
import moment from "moment";

export const Code = ({ code, getData }: { code: CodeType; getData?: () => void }) => {
  const [modal, setModal] = useState(false);
  return (
    <div>
      {modal && <ModalCode close={() => setModal(false)} code={code} />}
      <div className="grid md:grid-cols-[250px,1fr] gap-4 border-solid border-2 border-slate-200 rounded-2xl">
        <div className="px-6 md:mt-6 mt-4">
          <div className="flex items-center md:justify-start justify-center gap-2">
            <p className="text-lg font-bold">{code.id}</p>
            <Tooltip label="Details van deze code" hasArrow>
              <button
                onClick={() => setModal(true)}
                className="text-sm bg-blue-200 text-blue-500 p-2 rounded-full hover:opacity-80 transition-all"
              >
                <FaInfo />
              </button>
            </Tooltip>
          </div>

          {code.uploadedAt != null && (
            <p className="flex items-center gap-2 text-sm text-slate-400 md:justify-start justify-center">
              <FaUpload /> {code.uploadedAt.toDate && moment(code.uploadedAt.toDate()).format("dd D MMM HH:mm")}
            </p>
          )}
        </div>
        <div className="bg-slate-200 p-4 h-full w-full rounded-2xl flex flex-wrap gap-2">
          {code.photos.length === 0 && <p className="py-3 text-sm text-center">Deze code heeft nog geen foto's</p>}
          {code.photos.map((p, i) => (
            <img src={p.url_thumb ?? p.url} key={i} className="w-20 h-20 rounded-2xl object-fill" alt="" />
          ))}
          {code.expired && (
            <div>
              <p className="text-sm text-orange-500 bg-orange-100 p-2 rounded-lg">Deze code is verlopen</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
