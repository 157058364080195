import React, { Dispatch, SetStateAction, useState } from "react";
import Modal from "./Modal";
import Button from "components/forms/Button";
import { FaExclamation } from "react-icons/fa";
import firebase from "firebase";
import { Photo } from "@mijnfotohoek-types";

interface Props {
  uploadResult: Array<Photo>;
  selected: number;
  setSelected: Dispatch<SetStateAction<number>>;
  setUploadResult: Dispatch<SetStateAction<Array<Photo>>>;
}

export default function ModalCodesManual({ uploadResult, selected, setSelected, setUploadResult }: Props) {
  const p: Photo = uploadResult[selected];
  const [code, setCode] = useState(p === undefined || p.valueQR === null ? "" : uploadResult[selected].valueQR);
  const [codeError, setCodeError] = useState<Boolean | null>(false);
  const [loading, setLoading] = useState<Boolean>(false);
  const handleSave = async () => {
    setLoading(true);
    setCodeError(false);
    const exists = await checkCodeExists();
    if (code === "" || exists) {
      setUploadResult(
        uploadResult.map((d: Photo, i): Photo => {
          return i !== selected ? d : { ...d, valueQR: code };
        })
      );
      setSelected(-1);
    }
    if (code !== "" && !exists) setCodeError(true);
    setLoading(false);
  };

  const checkCodeExists = async () => {
    try {
      const docQuery = await firebase.firestore().collection("codes").doc(code).get();
      return docQuery.exists;
    } catch {
      return false;
    }
  };

  const handleChange = (value) => {
    setCode(value);
    let exists = null;
    setCodeError(exists);
  };
  return (
    <Modal close={() => setSelected(-1)} title="QR-code handmatig invullen">
      <div className="h-96 rounded-xl bg-gray-200 mt-5">
        <img className="h-96 block mx-auto rounded-xl" src={p.url} alt="" />
      </div>
      <div className="rounded_border_bottom rounded_border_left rounded_border_left_xl mt-10 py-2">
        <p>
          De downloadcode bestaat uit de tekens die na <span className="font-bold">mijnfotohoek.nl/code/</span> komen in
          de url boven de QR-code. Neem deze tekens over in het onderstaande tekstveld. Klik daarna op de "opslaan"-knop
          onderaan de pagina.
        </p>
        <input
          value={code}
          onChange={(e) => handleChange(e.target.value)}
          type="text"
          className="border-slate-200 border-solid border-2 rounded-xl py-1 px-4 text-lg mt-5 w-60"
        />
        {codeError && (
          <p className="mt-2 text-red-500 gap-2">
            <FaExclamation className="inline" /> Deze downloadcode bestaat niet. Controleer of je hem goed overgetypt
            hebt en probeer het dan opnieuw
          </p>
        )}
      </div>
      <div className="flex justify-end items-center gap-10 mt-10">
        <Button color="bg-transparent text-blue-500" onClick={() => setSelected(-1)}>
          Annuleren
        </Button>
        <Button loading={loading} onClick={handleSave}>
          Opslaan
        </Button>
      </div>
    </Modal>
  );
}
