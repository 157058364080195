import React from "react";
import { FaSearch } from "react-icons/fa";

export default function SearchBar({
  value,
  setFilter,
  placeholder = "Zoeken op downloadcode ...",
}: {
  value?: string;
  setFilter: (v: string) => void;
  placeholder?: string;
}) {
  return (
    <div className="relative w-full">
      <FaSearch className="left-4 top-[13px] absolute text-slate-400" />
      <input
        type="text"
        onChange={(e) => setFilter(e.target.value)}
        value={value}
        className="focus:border-blue-500 outline-none border-2 rounded-2xl py-2 block w-full pl-10"
        placeholder={placeholder}
      />
    </div>
  );
}
