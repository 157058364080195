import React, { useContext, useState } from "react";
import Button from "components/forms/Button";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Input } from "components/forms/Input";
import { PageAuth } from "components/layout/PageAuth";
import axios from "axios";
import firebase from "../firebase";
import { Link } from "react-router-dom";
import { DashboardContext } from "contexts/DashboardContext";
import { ModalTermsAndConditionsRegister } from "components/modals/ModalTermsAndConditionsRegister";
import { ModalPrivacyPolicy } from "components/modals/ModalPrivacyPolicy";

export const Register = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { firebaseBaseUrl } = useContext(DashboardContext);

  const handleRegister = async (
    values: {
      email: string;
      password: string;
      passwordConfirm: string;
    },
    actions
  ) => {
    setError("");
    setLoading(true);
    if (values.password !== values.passwordConfirm) {
      actions.setFieldError("passwordConfirm", "Wachtwoorden komen niet overeen");
      setLoading(false);
      return;
    }

    try {
      await axios({
        method: "post",
        headers: {
          "Content-Type": "text/plain",
        },
        url: `${firebaseBaseUrl}register`,
        data: {
          email: values.email,
          password: values.password,
        },
      });
      await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
      await firebase.auth().currentUser.sendEmailVerification();
    } catch (err: any) {
      if (err.response && err.response.data.error && err.response.data.error.code) {
        const code = err.response.data.error.code;
        switch (code) {
          case "auth/email-already-exists":
            actions.setFieldError("email", "Emailadres is al in gebruik");
            break;
          case "auth/invalid-email":
            actions.setFieldError("email", "Emailadres is ongeldig");
            break;
          case "auth/invalid-password":
            actions.setFieldError("password", "Wachtwoord is te zwak. Vul minimaal 6 tekens in.");
            break;
          case "auth/signup-disabled":
            setError("Registreren is tijdelijk uitgeschakeld. Probeer het later opnieuw.");
            break;
          default:
            setError(`Er is iets misgegaan. Probeer het later opnieuw. (${code})`);
            break;
        }
      } else {
        setError(`Er is iets misgegaan. Probeer het later opnieuw.`);
      }
    } finally {
      setLoading(false);
    }
  };

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  return (
    <PageAuth title="Account aanmaken">
      {showTermsAndConditions && <ModalTermsAndConditionsRegister close={() => setShowTermsAndConditions(false)} />}
      {showPrivacyPolicy && <ModalPrivacyPolicy close={() => setShowPrivacyPolicy(false)} />}
      <div className="max-w-md">
        <Formik
          validationSchema={Yup.object({
            email: Yup.string().email("Onjuist e-mailadres").required("Vereist"),
            password: Yup.string().required("Vereist"),
            passwordConfirm: Yup.string().required("Vereist"),
          })}
          onSubmit={(values, actions) => handleRegister(values, actions)}
          initialValues={{ email: "", password: "", passwordConfirm: "" }}
        >
          <Form>
            <div className="flex gap-4 flex-col">
              {error !== "" && <p className="text-red-500 font-bold">{error}</p>}
              <Input type="email" optional={false} label="Emailadres" name="email" />
              <Input type="password" optional={false} label="Wachtwoord" name="password" />
              <Input type="password" optional={false} label="Herhaal wachtwoord" name="passwordConfirm" />
              <Button type="submit" size="text-xl" loading={loading}>
                Aanmaken
              </Button>
            </div>
          </Form>
        </Formik>
        <div className="mt-10 text-blue-500">
          <Link to="/">Inloggen</Link>
        </div>
        <p className="mt-8 text-slate-500 text-xs">
          Bij het aanmaken ga je akkoord met de{" "}
          <button className="text-blue-500" onClick={() => setShowPrivacyPolicy(true)}>
            privacyverklaring
          </button>{" "}
          en de{" "}
          <button onClick={() => setShowTermsAndConditions(true)} className="text-blue-500">
            algemene voorwaarden
          </button>
          .
        </p>
      </div>
    </PageAuth>
  );
};
