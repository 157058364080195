import React from "react";
import Page from "components/layout/Page";

export default function Manual() {
  return (
    <Page>
      <h1>Handleiding</h1>
    </Page>
  );
}
