import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "components/forms/Button";
import firebase from "firebase";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Input } from "components/forms/Input";
import { PageAuth } from "components/layout/PageAuth";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const his = useHistory();
  const handleLogin = async ({ email, password }, actions) => {
    setLoading(true);
    setError("");
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      his.push("/dashboard/");
    } catch (err: any) {
      console.log(err);

      if (err.code != null) {
        const code = err.code;
        switch (code) {
          case "auth/user-not-found":
            actions.setFieldError("email", "Geen gebruiker gevonden met dit e-mailadres");
            break;
          case "auth/wrong-password":
            actions.setFieldError("password", "Het wachtwoord is onjuist");
            break;
          case "auth/invalid-email":
            actions.setFieldError("email", "Het e-mailadres is onjuist");
            break;
          case "auth/operation-not-allowed":
            setError(
              "Vanwege onderhoudswerkzaamheden is registreren en inloggen tijdelijk niet mogelijk. Probeer het later opnieuw."
            );
            break;
          case "auth/too-many-requests":
            setError(
              `De toegang tot dit account is tijdelijk uitgeschakeld vanwege vele mislukte inlogpogingen. U kunt het onmiddellijk herstellen door uw wachtwoord opnieuw in te stellen of u kunt het later opnieuw proberen.`
            );
            break;
          case "auth/user-disabled":
            setError("Dit account is geblokkeerd");
            break;
          default:
            setError(err.message);
        }
      } else {
        setError("Er is iets misgegaan. Probeer het later opnieuw.");
      }
    }
    setLoading(false);
  };

  return (
    <PageAuth title="Inloggen">
      <div className="max-w-md">
        <Formik
          validationSchema={Yup.object({
            email: Yup.string().email("Onjuist e-mailadres").required("Vereist"),
            password: Yup.string().required("Vereist"),
          })}
          onSubmit={(values, actions) => handleLogin(values, actions)}
          initialValues={{ email: "", password: "" }}
        >
          <Form>
            <div className="flex gap-4 flex-col">
              {error !== "" && <p className="text-red-500 font-bold">{error}</p>}
              <Input type="email" optional={false} label="Emailadres" name="email" />
              <Input type="password" optional={false} label="Wachtwoord" name="password" />
              <Button type="submit" size="text-xl" loading={loading}>
                Inloggen
              </Button>
            </div>
          </Form>
        </Formik>
        <p></p>
        <div className="mt-10 text-blue-500">
          <Link to="/register">Account aanmaken</Link>
        </div>
        <div className="mt-2 text-blue-500">
          <Link to="/reset">Wachtwoord resetten</Link>
        </div>
      </div>
    </PageAuth>
  );
};
