import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ResetPasswordHandler } from "./ResetPasswordHandler";
import { VerifyEmailHandler } from "./VerifyEmailHandler";

export const UserManagement = () => {
  const location = useLocation();
  const history = useHistory();
  const [mode, setMode] = useState(null);
  const [oobCode, setOobCode] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get("mode");
    const oobCode = params.get("oobCode");
    if ((mode !== "verifyEmail" && mode !== "resetPassword") || oobCode === null) {
      history.push("/");
      return;
    }
    setOobCode(oobCode);
    setMode(mode);
  }, [location]);

  return (
    <div>
      {mode === "verifyEmail" && <VerifyEmailHandler oobCode={oobCode} />}
      {mode === "resetPassword" && <ResetPasswordHandler oobCode={oobCode} />}
    </div>
  );
};
