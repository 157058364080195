import React from "react";
import Modal from "./Modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Input } from "components/forms/Input";
import Button from "components/forms/Button";
import firebase from "../../firebase";
import { DashboardContext } from "contexts/DashboardContext";

export const ModalChangePassword = ({ close }: { close: () => void }) => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { sendToast } = React.useContext(DashboardContext);
  const handleSubmit = async (values, actions) => {
    setLoading(true);
    setError("");

    try {
      await firebase.auth().signInWithEmailAndPassword(firebase.auth().currentUser.email, values.passwordOld);
    } catch (err: any) {
      if (err.code != null && err.code === "auth/wrong-password") {
        actions.setFieldError("passwordOld", "Huidig wachtwoord is onjuist");
      } else {
        setError("Er is iets misgegaan. Probeer het later opnieuw.");
      }
      setLoading(false);
      return;
    }

    if (values.password !== values.passwordConfirm) {
      actions.setFieldError("passwordConfirm", "Wachtwoorden komen niet overeen");
      setLoading(false);
      return;
    }
    if (values.password.length < 6) {
      actions.setFieldError("password", "Wachtwoord is te zwak. Vul minimaal 6 tekens in.");
      setLoading(false);
      return;
    }

    try {
      await firebase.auth().currentUser.updatePassword(values.password);
      sendToast("Wachtwoord is gewijzigd", null, "success");
      close();
    } catch (err: any) {
      setError("Er is iets misgegaan. Probeer het later opnieuw.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal close={close} title="Wachtwoord wijzigen">
      <div className="mt-4">
        <Formik
          validationSchema={Yup.object({
            passwordOld: Yup.string().required("Vereist"),
            password: Yup.string().required("Vereist"),
            passwordConfirm: Yup.string().required("Vereist"),
          })}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          initialValues={{ email: "", password: "", passwordConfirm: "" }}
        >
          <Form>
            <div className="flex gap-4 flex-col">
              {error !== "" && <p className="text-red-500 font-bold">{error}</p>}
              <Input type="password" optional={false} label="Huidige wachtwoord" name="passwordOld" />
              <Input type="password" optional={false} label="Nieuw wachtwoord" name="password" />
              <Input type="password" optional={false} label="Nieuw wachtwoord herhalen" name="passwordConfirm" />
              <Button type="submit" className="pt-1 pb-1" size="text-lg" loading={loading}>
                Wijzigen
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};
