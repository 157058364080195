import React from "react";

export const Hand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50.183" height="48.683" viewBox="0 0 50.183 48.683">
      <g id="Layer_2" data-name="Layer 2" transform="translate(-0.017 -0.001)">
        <g id="printer">
          <path
            id="Path_36"
            data-name="Path 36"
            d="M13.09,47.66a3.46,3.46,0,0,0,2,4.52L39,62.05l2.61-6.34L17.67,45.84a3.45,3.45,0,0,0-4.58,1.82Z"
            transform="translate(-11.37 -15.62)"
            fill="#ffdd67"
          />
          <path
            id="Path_37"
            data-name="Path 37"
            d="M47.34,41.81l2.78-6.75L22.82,23.8a3.65,3.65,0,0,0-4.74,2h0a3.67,3.67,0,0,0,2,4.77Z"
            transform="translate(-11.37 -15.62)"
            fill="#ffdd67"
          />
          <path
            id="Path_38"
            data-name="Path 38"
            d="M18.08,25.78h0a3.38,3.38,0,0,0-.18.62A3.65,3.65,0,0,1,22.33,25L49.62,36.26l.5-1.2L22.82,23.8a3.65,3.65,0,0,0-4.74,1.98Z"
            transform="translate(-11.37 -15.62)"
            fill="#eba352"
          />
          <path
            id="Path_39"
            data-name="Path 39"
            d="M41.6,55.71l2.87-7L16.61,37.27a3.78,3.78,0,0,0-4.95,2h0a3.78,3.78,0,0,0,2.1,4.92Z"
            transform="translate(-11.37 -15.62)"
            fill="#ffdd67"
          />
          <path
            id="Path_40"
            data-name="Path 40"
            d="M11.66,39.29h0a3.38,3.38,0,0,0-.18.62,3.79,3.79,0,0,1,4.65-1.45l17.33,7.15,2.25-.48-19.1-7.86a3.78,3.78,0,0,0-4.95,2.02Z"
            transform="translate(-11.37 -15.62)"
            fill="#eba352"
          />
          <path
            id="Path_41"
            data-name="Path 41"
            d="M42.21,47.83l2.86-7L17.22,29.39a3.78,3.78,0,0,0-5,2h0a3.78,3.78,0,0,0,2.09,4.92Z"
            transform="translate(-11.37 -15.62)"
            fill="#ffdd67"
          />
          <path
            id="Path_42"
            data-name="Path 42"
            d="M12.26,31.4h0a3.52,3.52,0,0,0-.18.61,3.77,3.77,0,0,1,4.64-1.44l19.6,8.08,2.24-.48-21.34-8.8a3.78,3.78,0,0,0-4.96,2.03Z"
            transform="translate(-11.37 -15.62)"
            fill="#eba352"
          />
          <path
            id="Path_43"
            data-name="Path 43"
            d="M13.09,47.66a5,5,0,0,0-.19.63A3.48,3.48,0,0,1,17.17,47L31.4,52.91l2.24-.48-16-6.59a3.45,3.45,0,0,0-4.55,1.82Z"
            transform="translate(-11.37 -15.62)"
            fill="#eba352"
          />
          <path
            id="Path_44"
            data-name="Path 44"
            d="M44.74,15.63c-3,.24-4.58,4.37-1.72,10.42,2,4.23,2.74,5.26,1.53,8.17L41.2,32.84S30.68,58.69,32.71,59.53c0,0,8.54,4.93,15.25,4.77C58,64.06,66.5,47,55.79,33.88,49.48,26.18,48.05,15.37,44.74,15.63Z"
            transform="translate(-11.37 -15.62)"
            fill="#ffdd67"
          />
          <path
            id="Path_45"
            data-name="Path 45"
            d="M44.74,15.63a3,3,0,0,0-1.54.54l.39-.06c3.31-.26,4.48,5.07,6.16,9.24,1.36,3.36,2.91,6.6,5.28,9,10.33,10.39,4.1,25.47-4.26,29.46,8.91-3.08,16.2-19.08,5.4-29.94-2.36-2.38-3.82-5.46-5.27-9C49.19,20.71,48.05,15.37,44.74,15.63Z"
            transform="translate(-11.37 -15.62)"
            fill="#eba352"
          />
          <path
            id="Path_46"
            data-name="Path 46"
            d="M44.88,33.33c-3.65,4.11-4.42,15.39,5.59,18-8.87-3.66-7.77-12.6-5.95-17C44.74,33.78,44.88,33.33,44.88,33.33Z"
            transform="translate(-11.37 -15.62)"
            fill="#eba352"
          />
        </g>
      </g>
    </svg>
  );
};
