import React, { useContext, useState } from "react";
import Page from "components/layout/Page";
import Card from "components/cards/Card";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { Input } from "components/forms/Input";
import Button from "components/forms/Button";
import firebase from "../firebase";
import { FaMinus, FaPlus } from "react-icons/fa";
// import faq.json file
import { faq } from "../utils/faq";
import { Link } from "react-router-dom";
import { FAQItem } from "@mijnfotohoek-types";
import { DashboardContext } from "contexts/DashboardContext";
import axios from "axios";

const FAQSection = ({ f }: { f: FAQItem }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="mb-8 border-b-2 w-full border-solid  border-slate-200">
      <div className="flex gap-2 items-center  cursor-pointer" onClick={() => setOpen(!open)}>
        <div className="text-blue-500 text-lg">{open ? <FaMinus /> : <FaPlus />}</div>
        <h1 className="font-bold text-lg">{f.question}</h1>
      </div>
      {open && (
        <div>
          <p className="text-slate-600 my-2">{f.answer}</p>
          {f.linkText && f.linkHref && f.linkHref.substring(0, 4) !== "http" && (
            <Link className="text-blue-500 underline" to={f.linkHref}>
              {f.linkText}
            </Link>
          )}
          {f.linkText && f.linkHref && f.linkHref.substring(0, 4) === "http" && (
            <a className="text-blue-500 underline" href={f.linkHref} target="_blank" rel="noreferrer">
              {f.linkText}
            </a>
          )}
          {f.imgHref && <img src={f.imgHref} alt="" className="rounded-lg max-w-lg mb-2" />}
        </div>
      )}
    </div>
  );
};

export default function Support() {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(faq[0].category);
  const { firebaseBaseUrl, sendToast } = useContext(DashboardContext);
  const handleSend = async (values, actions) => {
    setLoading(true);
    setError("");
    const token = await firebase.auth().currentUser.getIdToken(true);
    try {
      await axios({
        method: "post",
        url: `${firebaseBaseUrl}contact`,
        data: values,
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${token}`,
        },
      });
      actions.resetForm();
      sendToast(
        "Bericht verzonden",
        "Je bericht is verzonden. We zullen zo snel mogelijk contact met je opnemen.",
        "success"
      );
    } catch {
      sendToast("Er is iets misgegaan, probeer het later opnieuw", "", "error", null);
    }
    setLoading(false);
  };

  const handleResendVerificationEmail = async () => {
    setLoading(true);
    setError("");
    try {
      await firebase.auth().currentUser.sendEmailVerification({
        url: "https://app.mijnfotohoek.nl/dashboard",
      });
      sendToast(
        "Verificatie e-mail verzonden",
        `We hebben je een nieuwe verificatie e-mail gestuurd naar ${
          firebase.auth().currentUser.email
        }. Controleer je inbox en spambox.`,
        "success"
      );
    } catch (err) {
      console.log(err);
      sendToast("Er is iets misgegaan, probeer het later opnieuw", "", "error");
    }
    setLoading(false);
  };

  return (
    <Page>
      <Card title="Veelgestelde vragen">
        <div className="grid lg:grid-cols-[200px,1fr]">
          <div className="lg:block flex gap-2 mb-4 flex-wrap">
            {faq.map((c, i) => (
              <div key={i}>
                <button
                  className={`${
                    selectedCategory === c.category ? `text-blue-500` : `hover:underline text-slate-400`
                  } font-bold text-lg`}
                  onClick={() => setSelectedCategory(c.category)}
                >
                  {c.category}
                </button>
              </div>
            ))}
          </div>
          <div>
            {faq
              .filter((f) => f.category === selectedCategory)[0]
              .items.map((f, i) => (
                <FAQSection key={`${selectedCategory}-${i}`} f={f} />
              ))}
          </div>
        </div>
      </Card>
      <Card title="Contact" className="max-w-xl mt-8">
        {!firebase.auth().currentUser.emailVerified ? (
          <div>
            <p className="text-slate-500 mb-5">
              Je e-mailadres is nog niet geverifieerd. Je kunt pas contact opnemen met ons als je e-mailadres is
              geverifieerd. Controleer je e-mail en klik op de link.
            </p>
            <Button loading={loading} onClick={handleResendVerificationEmail}>
              Stuur verificatie-email opnieuw
            </Button>
          </div>
        ) : (
          <Formik
            validationSchema={object({
              subject: string().required("Vereist"),
              message: string().required("Vereist"),
              name: string().required("Vereist"),
              email: string().email("Ongeldig e-mailadres").required("Vereist"),
            })}
            onSubmit={(values, actions) => handleSend(values, actions)}
            initialValues={{ subject: "", message: "", name: "", email: firebase.auth().currentUser.email }}
          >
            <Form>
              <div className="flex gap-4 flex-col">
                {error !== "" && <p className="text-red-500 font-bold">{error}</p>}
                <Input optional={false} label="Onderwerp" name="subject" />
                <Input type="text" optional={false} label="Email" name="email" />
                <Input type="text" optional={false} label="Voornaam" name="name" />
                <Input type="textarea" optional={false} label="Bericht" name="message" />
                <Button type="submit" className="pt-1 pb-1" size="text-lg" loading={loading}>
                  Verzenden
                </Button>
              </div>
            </Form>
          </Formik>
        )}
      </Card>
    </Page>
  );
}
