import { faFile, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalEmailVerification } from "components/modals/ModalEmailVerification";
import { ModalPrompt } from "components/modals/ModalPrompt";
import { DashboardContext } from "contexts/DashboardContext";
import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import firebase from "../../firebase";

export const DropzoneFileList = ({ files, setFiles, upload, uploading = false }) => {
  const removePhoto = (index) => {
    setFiles(files.filter((f, i) => i !== index));
  };

  const { checkFiles, checkStorage } = React.useContext(DashboardContext);
  const [rejectedFiles, setRejectedFiles] = React.useState({});
  const [storageSurpass, setStorageSurpass] = React.useState(0);
  const { userData } = React.useContext(DashboardContext);
  const [modalVerificationEmail, setModalVerificationEmail] = React.useState(false);

  const preupload = async () => {
    // await firebase.auth().currentUser.reload();
    if (!firebase.auth().currentUser.emailVerified) {
      setModalVerificationEmail(true);
      return;
    }
    setRejectedFiles({});
    const f = checkFiles(files);
    if (!f.hasValidFiles) {
      setRejectedFiles(f.rejected);
      setModalInvalidFile(true);
      return;
    }
    const s = checkStorage(files);
    if (!s.hasEnough) {
      setStorageSurpass(s.surpass);
      setModalStorage(true);
      return;
    }
    upload(files);
  };

  const [modalStorage, setModalStorage] = React.useState(false);
  const [modalInvalidFile, setModalInvalidFile] = React.useState(false);

  return (
    <div>
      <div className="max-h-[300px] mb-8 overflow-y-auto pr-2">
        {modalVerificationEmail && <ModalEmailVerification close={() => setModalVerificationEmail(false)} />}
        {modalStorage && (
          <ModalPrompt
            title="Uploaden niet mogelijk"
            description={`Je hebt niet genoeg opslagruimte om deze foto's te uploaden (${Math.floor(
              storageSurpass / 1000000
            )} MB teveel).
            `}
            onConfirm={() => setModalStorage(false)}
            confirmText="Oké"
          >
            {userData.currentSubscription === null && (
              <div>
                <p className="text-sm text-slate-700">
                  Je kunt je opslagruimte uitbreiden door een abonnement af te sluiten.{" "}
                  <Link to="/dashboard/upgrade" className="underline">
                    Klik hier om te upgraden naar Premium.
                  </Link>
                </p>
              </div>
            )}
          </ModalPrompt>
        )}
        {modalInvalidFile && (
          <ModalPrompt
            title="Uploaden niet mogelijk"
            description="Je probeert een of meerdere bestanden te uploaden die niet geldig zijn. Controleer de lijst met bestanden en verwijder de ongeldige bestanden."
            onConfirm={() => setModalInvalidFile(false)}
            confirmText="Oké"
          >
            <div className="max-h-96 overflow-y-scroll">
              {Object.entries(rejectedFiles).map(([keyValue, value]) => (
                <div key={keyValue} className="border-b-2 border-slate-200 border-solid mb-2">
                  <p className="text-sm font-bold">{keyValue}</p> <p className="text-red-500 text-xs">{value}</p>
                </div>
              ))}
            </div>
          </ModalPrompt>
        )}
        {files.map((f, i) => (
          <div className="border-b-2 border-solid border-gray-200" key={i}>
            <div className="grid grid-cols-[auto,1fr,auto] gap-4  items-center">
              <FontAwesomeIcon icon={faFile} className="text-slate-500" />
              <p className="truncate">{f.name}</p>
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => removePhoto(i)}
                className="dropzone_file_list_remove link cursor-pointer hover:opacity-80"
              />
            </div>
            {rejectedFiles[f.name] && <p className="text-red-500 font-bold text-xs">Error: {rejectedFiles[f.name]}</p>}
          </div>
        ))}
      </div>
      <Button className="w-full" onClick={preupload} loading={uploading}>
        {files.length} foto{files.length === 1 ? `` : `'s`} uploaden
      </Button>
    </div>
  );
};
