import { FAQ } from "@mijnfotohoek-types";

export const faq: Array<FAQ> = [
  {
    category: "Algemeen",
    items: [
      {
        question: "Wat is mijnfotohoek.nl?",
        answer: `MijnFotohoek biedt jou een platform om je foto's te delen op een privacybestendige manier. Je maakt bijvoorbeeld foto's op een feestje en je wilt deze delen met de gasten. Je wilt niet dat iedereen elkaars foto's ziet. Via MijnFotohoek kan je iedereen die je fotografeert een uitgeprint kaartje meegeven. Dit kaartje heeft een persoonlijke QR-code naar onze downloadpagina. Zo kan iedereen hun eigen foto's bekijken en downloaden, maar kunnen ze niet bij de foto's van anderen. `,
      },
      {
        question: "Hoe werkt mijnfotohoek.nl?",
        answer:
          "Via het dashboard kan je een aantal QR-codes aanmaken. Deze print je vervolgens uit en neem je mee naar de locatie waar je gaat fotograferen. Hier ga je als volgt te werk: je maakt eerst een duidelijke foto van een van de QR-codes. Daarna zet je de personen op de foto. Vervolgens geef je de QR-code die je net gefotografeerd hebt mee aan een van de personen op de foto. Je uiteindelijke fotoreportage zal dus bestaan uit gefotografeerde mensen, afgewisseled met foto's van QR-codes. Deze foto's kan je vervolgens uploaden via het dashboard. De QR-codes worden tijdens het uploaden automatisch herkend en de foto's worden gekoppeld aan de juiste QR-code. De personen op de foto's kunnen vervolgens de foto's downloaden via de QR-code die ze gekregen hebben. ",
      },
      {
        question: "Hoe ziet de downloadpagina eruit?",
        answer: "",
        linkHref: "https://www.mijnfotohoek.nl/code/HDCFQNDK7L",
        linkText: "Zie hier een voorbeeld van de downloadpagina",
      },
      {
        question: "Hoe zien de kaartjes eruit die ik moet printen?",
        answer:
          "Zo zien de kaartjes eruit. Ze worden standaard geleverd in een A4 formaat. Je kan daarna met je eigen PDF-reader de kaartjes op een ander formaat printen indien je dit wilt.",
        imgHref: "/kaartjes_voorbeeld.JPEG",
      },
    ],
  },
  {
    category: "Abonnement",
    items: [
      {
        question: "Welke extra's bied een abonnement?",
        answer:
          "Met een abonnement krijg je tot 10 GB extra opslag, onbeperkt QR-codes aanmaken, inzicht in je statistieken en meer. Upgraden kan via de instellingen.",
        linkHref: "/dashboard/upgrade",
        linkText: "Klik hier om te upgraden.",
      },
      {
        question: "Hoe kan ik betalen?",
        answer: "Je kan betalen met iDeal.",
        linkHref: "/dashboard/upgrade",
        linkText: "Klik hier om te upgraden.",
      },
      {
        question: "Waar kan ik een factuur van mijn abonnement vinden?",
        answer:
          "Je kan een factuur vinden in je accountspagina. Onder het kopje 'Abonnement' (halverwege de pagina) staat een blauwe knop met 'Facturen'. Hier kun je je recente betalingen zien en vervolgens de factuur downloaden voor iedere betaling.",
        linkHref: "/dashboard/account",
        linkText: "Klik hier om naar je accountspagina te gaan.",
      },
      {
        question: "Wat is het terugbetalingsbeleid?",
        answer:
          "Het terugbetalingstermijn is 14 dagen. Je kan hiervoor contact opnemen met onze klantenservice via het onderstaande formulier. Wij zullen je verzoek zo snel mogelijk in behandeling nemen. Je krijgt daarna het geld teruggestort op je rekening. Let op: je abonnement zal dan wel stopgezet worden.",
      },
      {
        question: "Kan ik gebruik maken van een proefperiode?",
        answer:
          "Wij bieden geen proefperiode aan. Mocht je niet tevreden zijn, dan kan je binnen 14 dagen je geld terugvragen.",
        linkHref: "/dashboard/upgrade",
        linkText: "Klik hier om te upgraden.",
      },
    ],
  },
  {
    category: "Foto's",
    items: [
      {
        question: "Hoe worden mijn foto's gekoppeld aan de QR-codes?",
        answer:
          "Wij koppelen de foto's aan de QR-codes op basis van de volgorde waarin je de foto's uploadt. Maak tijdens het fotograferen ook foto's van de QR-codes. Deze foto's worden herkend door onze software, en de foto's die na de QR-codes zijn gemaakt worden gekoppeld aan de QR-code. Upload dus alle foto's in een keer, inclusief de foto's van de QR-codes. Uploaden kan via de homepage.",
        linkHref: "/dashboard/",
        linkText: "Klik hier om naar de homepage te gaan.",
      },
      {
        question: "Hoe kunnen de foto's worden gedownload?",
        answer:
          "De QR-code kan gescand worden met een smartphone. De gebruiker wordt vervolgens doorverwezen naar de downloadpagina van de foto's. Gebruikers kunnen ook naar de downloadpagina gaan via de link die boven de QR-code staat. Het is daarom belangrijk dat je niet vergeet de QR-codes uit te delen.",
      },
    ],
  },
  {
    category: "QR-codes",
    items: [
      {
        question: "Kan ik de vouchers met QR-codes op een kleiner formaat krijgen dan A4?",
        answer:
          "Wij bieden geen functonaliteit om de vouchers om een kleiner formaat te maken. Je kan wel in je eigen software het bestand met de QR-codes op een kleiner formaat afdrukken.",
      },
      {
        question: "Kan ik het ontwerp van de QR-codes vouchers aanpassen?",
        answer:
          "Nee, wij bieden geen functonaliteit om het ontwerp van de QR-codes vouchers aan te passen. Wellicht komen wij in de toekomst met een functie om de lay-out van de vouchers aan te passen, maar vooralsnog is dit niet mogelijk.",
      },
      {
        question: "Regelmatig worden QR-codes niet herkend door jullie software. Wat kan ik hieraan doen?",
        answer:
          "Probeer de foto's met QR-codes te bewerken met een programma als Photoshop of Gimp. Snijdt de foto bij en verhoog het contrast en de verlichting. Dit kan de herkenbaarheid van de QR-codes verbeteren. Mocht je hierna nog steeds structureel problemen ondervinden, neem dan contact op met onze klantenservice via het onderstaande contactformulier.",
      },
      {
        question: "Kan ik de geldigheid van QR-codes verlengen?",
        answer:
          "De standaardtermijn van een QR-code is 1 maand bij een gratis account en 3 maanden bij een premium account. Je kan deze termijnen niet verlengen.",
      },
      {
        question: "Kan ik de QR-codes opnieuw genereren?",
        answer:
          "Nee, dit is niet mogelijk. Bewaar het bestand met de QR-codes dus goed als je het meerdere keren nodig hebt.",
      },
    ],
  },
  {
    category: "Account",
    items: [
      {
        question: "Hoe kan ik mijn account verwijderen?",
        answer:
          "Neem hiervoor contact op met onze klantenservice via het onderstaande formulier. Wij zullen je verzoek zo snel mogelijk in behandeling nemen.",
      },
      {
        question: "Hoe kan ik mijn accountgegevens wijzigen?",
        answer:
          "Je kan je accountgegevens wijzigen via je account-pagina. Deze kan je vinden door linksbovenin op 'account' te klikken.",
        linkHref: "/dashboard/account",
        linkText: "Klik hier om naar je account-pagina te gaan",
      },
      {
        question: "Hoe kan ik mijn wachtwoord wijzigen?",
        answer:
          "Je kan je wachtwoord wijzigen via je account-pagina. Deze kan je vinden door linksbovenin op 'account' te klikken",
        linkHref: "/dashboard/account?resetPassword=true",
        linkText: "Klik hier om je wachtwoord te wijzigen",
      },
      {
        question: "Ik heb meer dan 10 GB aan opslag nodig voor mijn account. Is dit mogelijk?",
        answer:
          "Neem hiervoor contact op met onze klantenservice via het onderstaande formulier. Wij zullen kijken wat de mogelijkheden zijn. Let op: dit is alleen mogelijk bij een premium account. Het kan zijn dat wij je hier meer voor in rekening brengen.",
      },
    ],
  },
  {
    category: "Overig",
    items: [
      {
        question: "Ik heb een vraag die hier niet bij staat. Wat nu?",
        answer: "Neem dan contact op met onze klantenservice via het onderstaande formulier.",
      },
      {
        question: "Ik heb een suggestie voor jullie software. Waar kan ik deze kwijt?",
        answer:
          "Wij horen graag van je! Neem contact op met onze klantenservice via het onderstaande formulier. Wij zullen je suggestie in behandeling nemen. Mocht je suggestie worden geïmplementeerd, dan maak je kans op een gratis abonnement (of verlenging van je abonnement)!",
      },
      {
        question: "Ik heb een bug gevonden in jullie software. Waar kan ik deze melden?",
        answer:
          "Neem contact op met onze klantenservice via het onderstaande formulier. Vermeld in het onderwerp duidelijk het woord 'bug'. Wij kunnen je melding zo sneller in behandeling nemen.",
      },
      {
        question: "Hoe lang duurt het voordat ik een reactie krijg als ik contact opneem via het contactformulier?",
        answer:
          "Wij streven er na om binnen een paar dagen een reactie te geven. Heb je een premium account? Dan streven wij er naar om binnen 24 uur een reactie te geven. Mocht je na 5 werkdagen nog geen reactie hebben ontvangen, probeer dan het contactformulier nogmaals in te vullen.",
      },
      {
        question: "Waar kan ik de algemene voorwaarden vinden?",
        answer: "De algemene voorwaarden kan je in je account-pagina vinden.",
        linkHref: "/dashboard/account",
        linkText: "Klik hier om naar je account-pagina te gaan",
      },
      {
        question: "Waar kan ik de jullie privacybeleid vinden?",
        answer: "Ons privacybeleid kan je in je account-pagina vinden.",
        linkHref: "/dashboard/account",
        linkText: "Klik hier om naar je account-pagina te gaan",
      },
    ],
  },
];
