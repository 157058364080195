import React from "react";
import Modal from "./Modal";
import { Printer } from "../icons/Printer";
import { CameraQRCode } from "../icons/CameraQRCode";
import { Hand } from "../icons/Hand";
import { Laptop } from "../icons/Laptop";
import Button from "../forms/Button";
import { motion } from "framer-motion";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const Instruction = ({ title, instruction, icon }) => {
  return (
    <motion.div variants={item}>
      <div className="h-20 flex items-center">{icon}</div>
      <p className="text-xl text-slate-600 font-semibold">{title}</p>
      <p className="text-sm text-slate-400">{instruction}</p>
    </motion.div>
  );
};

export default function ModalCodesDownloadSuccess({ closeAction, createResult }) {
  return (
    <Modal width={600}>
      <div className="my-4">
        <div>
          <h1 className="text-center text-2xl font-semibold">
            Wij hebben {createResult.codes.length} QR-code{createResult.codes.length === 1 ? `` : `s`} voor je fotohoek
            aangemaakt!
          </h1>
          <p className="text-center text-slate-400">Dit is wat je nu moet doen:</p>
        </div>
        <motion.div variants={container} initial="hidden" animate="show" className=" my-4 grid grid-cols-2 gap-4">
          <Instruction
            title="1. Printen"
            instruction="Print het gedownloade bestand uit. In dit bestand vind je alle QR-codes. Elke QR-code bevat een unieke downloadlink."
            icon={<Printer />}
          />
          <Instruction
            title="2. Fotograferen"
            instruction="Zodra je iemand op de foto gaat zetten in je fotohoek, maak je eerst een foto van één van de unieke QR-codes. Zet de persoon daarna op de foto."
            icon={<CameraQRCode />}
          />
          <Instruction
            title="3. Uitdelen"
            instruction="Geef de QR-code die je net gefotografeerd hebt mee aan de gefotografeerde persoon. Herhaal stap 2 en 3 zolang je aan het fotograferen bent."
            icon={<Hand />}
          />
          <Instruction
            title="4. Uploaden"
            instruction="Zet je foto's op je computer en upload ze (inclusief de foto's van de QR-codes) via het dashboard. De foto's die na een gefotografeerde QR-code komen, worden aan die QR-code gekoppeld. Zorg dat de foto's dus op volgorde staan (op basis van bestandsnaam)."
            icon={<Laptop />}
          />
        </motion.div>
        <p className="text-xs text-slate-400 text-center">
          Je kan de bovenstaande instructies nogmaals lezen in de gedownloade PDF
        </p>
        <div>
          <Button className=" mt-4 block w-full" onClick={closeAction}>
            Sluiten
          </Button>
        </div>
      </div>
    </Modal>
  );
}
