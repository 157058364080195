import React, { useContext, useState } from "react";
import Button from "../forms/Button";
import Card from "./Card";
import { CodesContext } from "../../contexts/CodesContext";
import { DashboardContext } from "../../contexts/DashboardContext";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Input } from "../forms/Input";
import ModalCodesDownloadSuccess from "../modals/ModalCodesDownloadSuccess";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import { ModalEmailVerification } from "components/modals/ModalEmailVerification";

const LimitMessage = () => {
  return (
    <div>
      <h1 className="text-xl font-bold">Je hebt je limiet bereikt </h1>
      <p className="text-slate-400">
        <Link to="/dashboard/upgrade" className="underline hover:opacity-80">
          Upgrade
        </Link>{" "}
        je account naar premium om weer QR-codes te kunnen maken
      </p>
    </div>
  );
};

export default function CardDashboardCodes() {
  const { setAmount, loadingCreate, setLoadingCreate, createResult, setCreateResult } = useContext(CodesContext);
  const { firebaseBaseUrl, userData, sendToast } = useContext(DashboardContext);
  const [modalEmailNotVerified, setModalEmailNotVerified] = useState(false);
  const [url, setUrl] = useState(null);
  const handleCreate = async (amount: number) => {
    if (userData.codesLimit != null && amount > userData.codesLimit - userData.codes) {
      sendToast("Je kan maximaal nog " + (userData.codesLimit - userData.codes) + " codes maken", null, "warning");
      return;
    }
    if (amount > 250) {
      sendToast("Je kan maximaal 250 codes tegelijker maken", null, "warning");
      return;
    }
    if (!loadingCreate) {
      await firebase.auth().currentUser.reload();
      if (!firebase.auth().currentUser.emailVerified) {
        setModalEmailNotVerified(true);
        return;
      }
      setLoadingCreate(true);
      setAmount(amount);
      try {
        const token = await firebase.auth().currentUser.getIdToken(true);
        const { data } = await axios({
          method: "post",
          url: `${firebaseBaseUrl}createCodes`,
          params: { amount },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCreateResult(data);

        const { data: data_pdf } = await axios({
          url: `${firebaseBaseUrl}createCodesPDF`,
          method: "post",
          params: {
            id: data.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setUrl({ pdf: `data:application/pdf;base64,${data_pdf.pdf}`, fileName: data_pdf.fileName });
        setTimeout(() => {
          document.getElementById("download").click();
          setModalCodesDownloadSuccess(true);
        }, 300);
      } catch (err) {
        sendToast("Serverfout", "Probeer het opnieuw", "error");
      }
      setLoadingCreate(false);
    }
  };

  const [modalCodesDownloadSuccess, setModalCodesDownloadSuccess] = useState(false);
  const reachedLimit = userData.codesLimit != null && userData.codesLimit - userData.codes <= 0;
  return (
    <Card className="md:col-start-1 md:col-end-3" title="📸 Creeër jouw fotohoek">
      {modalEmailNotVerified && <ModalEmailVerification close={() => setModalEmailNotVerified(false)} />}
      {modalCodesDownloadSuccess && (
        <ModalCodesDownloadSuccess
          createResult={createResult}
          closeAction={() => setModalCodesDownloadSuccess(false)}
        />
      )}
      {reachedLimit && !loadingCreate ? (
        <LimitMessage />
      ) : (
        <div className="flex flex-col gap-4">
          <h1 className="text-xl font-semibold">Hoeveel personen ga je ongeveer fotograferen?</h1>
          <Formik
            validationSchema={Yup.object({
              amount: Yup.number()
                .typeError("Vul een getal in")
                .min(1, "Vul een getal hoger dan 0 in")
                .max(500, "Je kan maximaal 500 codes tegelijkertijd aanmaken")
                .required("Vereist"),
            })}
            onSubmit={(values) => handleCreate(values.amount)}
            initialValues={{ amount: 1 }}
          >
            <Form>
              <div className="mb-4 flex gap-4">
                <Input type="number" name="amount" className="max-w-[150px] text-xl" classNameError="max-w-[150px]" />
                {userData != null && userData.codesLimit != null && (
                  <div className="text-sm text-slate-400">
                    <p>
                      Je kan nog <span className="text-lg">{userData.codesLimit - userData.codes}</span> keer aanmaken
                    </p>
                    <p className="mt-[-4px]">
                      <Link to="/dashboard/upgrade" className="underline mr-1">
                        Upgrade
                      </Link>
                      voor meer
                    </p>
                  </div>
                )}
              </div>
              <Button type="submit" className="inline" loading={loadingCreate}>
                Aanmaken
              </Button>
              {loadingCreate && <p className="text-sm text-blue-500 mt-4">Bezig met aanmaken. Dit kan even duren ..</p>}
            </Form>
          </Formik>
        </div>
      )}
      {url != null && (
        <a style={{ display: "none" }} href={url.pdf} download={url.fileName} id="download">
          download
        </a>
      )}
    </Card>
  );
}
