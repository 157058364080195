import Button from "components/forms/Button";
import React from "react";
import { Terms } from "./components/Terms";
import Modal from "./Modal";

export const ModalTermsAndConditionsRegister = ({ close }) => {
  return (
    <Modal title="Algemene voorwaarden" close={close}>
      <Terms>
        Mijnfotohoek.nl (Kamer van Koophandel: 72163216) (hierna te noemen mijnfotohoek) verleent u via uw account
        registratie toegang tot de functionaliteiten van mijnfotohoek. Mijnfotohoek behoudt het recht om zonder opgaaf
        van redenen functionaliteiten aan te passen of te verwijderen.
      </Terms>
      <Terms title="Aansprakelijkheid">
        Ons doel is om de applicatie zo goed mogelijk te laten functioneren. Echter, het is niet altijd mogelijk om
        systeemfouten te voorkomen. Wij zijn niet aansprakelijk voor eventuele schade die kan voortvloeien uit
        systeemfouten. We doen ons best om systeemfouten zo goed mogelijk te communiceren en zo snel mogelijk op te
        lossen. Als u een systeemfout tegenkomt of als u suggesties heeft voor verbeteringen, horen we dat graag via het
        contactformulier of via info@mijnfotohoek.nl
        <br />
        Het is verboden om op enigerlei wijze misbruik te maken van systeemfouten. We behouden ons het recht om uw
        account te blokkeren als dit gebeurt. Onder systeemfouten vallen onder andere bugs, (korte of langdurige)
        systeemuitvallen, het verdwijnen van data en hacks.
      </Terms>
      <Terms title="Gebruik van mijnfotohoek">
        Mijnfotohoek is een systeem om foto's te delen. Het is niet toegestaan om aanstootgevende of onwetteljke beelden
        te uploaden via mijnfotohoek. Hieronder vallen onder andere foto's met pornografische, racistische,
        discriminerende, gewelddadige en/of beledigende aspecten of andere beelden die in strijd zijn met de Nederlandse
        wet. Mijnfotohoek kan zonder opgaaf van redenen downloadpagina's, foto's, QR-codes en/of uw account verwijderen
        en deactiveren. Het is niet toegestaan om beelden op mijnfotohoek te uploaden waar u geen (auteursrechtelijke)
        rechten over heeft.
        <br />
        De QR-codes die u aanmaakt zijn een maand geldig vanaf het moment van aanmaken (en dus niet vanaf het moment van
        uploaden). Na deze maand worden de foto's permanent verwijderd. Mijnfotohoek is niet aansprakelijk voor verlies
        van deze foto's. De foto's kunnen niet worden teruggehaald. Sluit u een premium abonnement af, dan blijven de
        QR-codes 3 maanden geldig.
        <br />
        Voor het gebruiken van mijnfotohoek dient u minimaal 16 jaar oud te zijn.
      </Terms>
      <Terms title="Wijzigen">
        Mijnfotohoek behoudt zich het recht voor om deze algemene voorwaarden te wijzigen. Wijzigingen worden per mail
        aan u meegedeeld. Indien u niet akkoord gaat met de wijzigingen, kunt u uw account opzeggen door een mail te
        sturen naar info@mijnfotohoek.nl. Indien u akkoord gaat met de wijzigingen, gaat u automatisch akkoord met de
        nieuwe voorwaarden. Mijnfotohoek behoudt zich het recht voor om uw account te blokkeren indien u niet akkoord
        gaat met de wijzigingen.
      </Terms>
      <Terms title="Auteursrechten">
        Het is niet toegestaan om logo's, code en/of andere materialen van mijnfotohoek te
        kopiëren/gebruiken/verspreiden zonder toestemming van mijnfotohoek.
      </Terms>
      <Terms title="Premium abonnement">
        Via mijnfotohoek kunt u een premium abonnement afsluiten. Hier gelden aanvullende voorwaarden voor. U kunt deze
        voorwaarden lezen op app.mijnfotohoek.nl/dashboard/upgrade. U dient akkoord te gaan met deze voorwaarden
        voorafgaand aan het afsluiten van een abonnement.
      </Terms>
      <Button onClick={close} className="mt-8">
        Sluiten
      </Button>
    </Modal>
  );
};
