import React, { createContext, Dispatch, SetStateAction, useState } from "react";

interface CreateResult {
  codes: Array<string>;
  id: string;
}

interface CodesContextProps {
  amount: number;
  setAmount: (amount: number) => void;
  loadingCreate: Boolean;
  setLoadingCreate: Dispatch<SetStateAction<boolean>>;
  createResult: CreateResult;
  setCreateResult: (result: CreateResult) => void;
  loadingDownload: Boolean;
  setLoadingDownload: Dispatch<SetStateAction<boolean>>;
}

export const CodesContext = createContext<CodesContextProps>(null);

const CodesContextProvider = (props) => {
  const [amount, setAmount] = useState(1);
  const [createResult, setCreateResult] = useState(null);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  return (
    <CodesContext.Provider
      value={{
        amount,
        setAmount,
        loadingCreate,
        setLoadingCreate,
        createResult,
        setCreateResult,
        loadingDownload,
        setLoadingDownload,
      }}
    >
      {props.children}
    </CodesContext.Provider>
  );
};
export default CodesContextProvider;
