import React from "react";

export const Terms = ({ title, children }: { title?: string; children: React.ReactNode }) => {
  return (
    <div className="mt-8">
      {title && <h2 className="text-xl font-bold">{title}</h2>}
      <p className="text-slate-500">{children}</p>
    </div>
  );
};
