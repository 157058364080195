import { faHome, faQuestion, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import firebase from "firebase";
import styled from "styled-components";
import { motion } from "framer-motion";
import { DashboardContext } from "contexts/DashboardContext";
import Button from "components/forms/Button";

const HeaderBg = styled.div`
  // color: #073c92;
  color: white;
  padding-top: 10px;
  // text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.08);
  &::before {
    z-index: 0;
    content: " ";
    position: absolute;
    width: 100%;
    height: 300px;
    left: 0;
    top: 0;
    background-image: url("/background.jpg");
    background-size: cover;
    background-position: top;
  }
`;

const navs = [
  {
    label: "Dashboard",
    route: "/dashboard",
    icon: faHome,
  },
  {
    label: "Support",
    route: "/dashboard/support",
    icon: faQuestion,
  },
  {
    label: "Account",
    route: "/dashboard/account",
    icon: faUser,
  },
];

export default function Header({ elements = true }) {
  const { userData } = React.useContext(DashboardContext);
  return (
    <HeaderBg className="mb-5 md:mb-10  bg-cover bg-center">
      {elements && (
        <div className="flex md:flex-row flex-col gap-2 md:gap-0 justify-between items-center">
          <div className="md:text-left text-center">
            <Link to="/dashboard/" className="text-3xl font-semibold">
              mijnfotohoek.nl
            </Link>
            <div className="flex md:gap-10 gap-5 my-2 md:my-0 justify-self-center self-center font-light">
              {navs.map((n) => (
                <motion.div whileHover={{ scale: 1.05 }}>
                  <NavLink className="hover-animation" activeClassName="font-bold" to={n.route} exact={true}>
                    <FontAwesomeIcon icon={n.icon} /> {n.label}
                  </NavLink>
                </motion.div>
              ))}
            </div>
          </div>
          <div className="relative sm:flex-row items-center flex-col flex gap-4">
            <div>
              {userData.currentSubscription == null && (
                <Link to="/dashboard/upgrade/">
                  <Button className="z-10">Upgrade</Button>
                </Link>
              )}
            </div>
            <div className="user text-center md:text-right z-10">
              <p className="font-semibold">{firebase.auth().currentUser.email}</p>
              <motion.button
                whileHover={{ scale: 1.1 }}
                className="text-white z-10 no-underline relative font-light"
                onClick={() => firebase.auth().signOut()}
              >
                Uitloggen
              </motion.button>
            </div>
          </div>
        </div>
      )}
    </HeaderBg>
  );
}
