import Button from "components/forms/Button";
import React from "react";
import { Terms } from "./components/Terms";
import Modal from "./Modal";

export const ModalTermsAndConditionsPremium = ({ close }) => {
  return (
    <Modal title="Algemene voorwaarden premium abonnement" close={close}>
      <Terms>
        Bij het afsluiten van een abonnement dient u akkoord te gaan met de aanvullende algemene voorwaarden voor een
        abonnement. Deze kunt u hier lezen.
      </Terms>
      <Terms title="Functionaliteiten">
        Een abonnement geeft u toegang tot de volgende functionaliteiten:
        <ul className="list-disc list-inside">
          <li>10 GB aan opslag (voorheen 100 MB)</li>
          <li>Geen limiet op het aantal QR-codes aanmaken (voorheen 100)</li>
          <li>Geen limiet op het aantal foto's dat u kunt uploaden (voorheen 200)</li>
          <li>
            Langere geldigheid van QR-codes: maximaal 3 maanden (voorheen 1 maand), tenzij u de code handmatig
            verwijderd
          </li>
        </ul>
      </Terms>
      <Terms title="Levering en opzeggen">
        Uw abonnement wordt direct na betaling geactiveerd. U kunt direct gebruik maken van de functionaliteiten. Het
        abonnement blijft voor onbepaalde tijd geldig, totdat u deze opzegt. Opzeggen kan via uw accounts pagina, welke
        te vinden is via de link in de header van de pagina. Uw abonnement zal dan aflopen op het einde van de lopende
        maand of het lopende jaar, afhankelijk van welk type abonnement u heeft. Daarna zal uw abonnement niet verlengd
        worden en gaat u terug naar de gratis versie, met bijbehorende beperkingen en limieten (o.a. 100 qr-codes
        aanmaken, 200 foto's uploaden, 100 MB aan opslag). Bij misbruik van het abonnement heeft mijnfotohoek het recht
        om zonder opgaaf van redenen uw account te deactiveren en uw gegevens te verwijderen.
      </Terms>
      <Terms title="Wijzigingen in gebruik">
        Wanneer u een premium abonnement heeft, zijn QR-codes 3 maanden geldig in plaats van 1 maand. De QR-code wordt
        drie maanden na het aanmaken gedeactiveerd. Eventuele foto's gekoppeld aan de QR-code worden permanent
        verwijderd. Dit geldt alleen voor QR-codes die aangemaakt zijn terwijl u een premium abonnement had. QR-codes
        die aangemaakt zijn voor het afsluiten van een abonnement blijven 1 maand geldig.
      </Terms>
      <Terms title="Terugbetalingstermijn">
        De terugbetalingstermijn bedraagt 14 dagen. Wanneer u gebruikt maakt van de terugbetalingstermijn van 14 dagen,
        dan wordt uw premium abonnement direct opgezegd. U kunt dan geen gebruik meer maken van uw premium abonnement. U
        kunt een terugbetaling aanvragen door een mail te sturen naar info@mijnfotohoek.nl of door een bericht te sturen
        via de support-pagina.
      </Terms>
      <Terms title="Algemene voorwaarden en privacybeleid">
        Wanneer u een abonnement afsluit, gaat u akkoord met deze voorwaarden, de algemene voorwaarden en het
        privacybeleid. De laatste twee genoemde zijn te vinden op uw accountspagina.
      </Terms>
      <div className="mt-8">
        <Button onClick={close}>Sluiten</Button>
      </div>
    </Modal>
  );
};
