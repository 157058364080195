import { PeriodUnits } from "@mijnfotohoek-types";

export const translatePeriodUnit = (unit: PeriodUnits, value: number): string => {
  switch (unit) {
    case "days":
      return value === 1 ? "dag" : "dagen";
    case "weeks":
      return value === 1 ? "week" : "weken";
    case "months":
      if (value === 12) return "jaar";
      return value === 1 ? "maand" : "maanden";
    default:
      return unit;
  }
};
