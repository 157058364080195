import Button from "components/forms/Button";
import { PageSideBarLayout } from "components/layout/PageSideBarLayout";
import React from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <PageSideBarLayout title="Pagina niet gevonden">
      <p className="mb-8">De pagina die je probeert te bezoeken bestaat niet.</p>
      <Button>
        <Link to="/">Ga terug naar de homepage</Link>
      </Button>
    </PageSideBarLayout>
  );
};
