import axios from "axios";
import Button from "components/forms/Button";
import { DashboardContext } from "contexts/DashboardContext";
import moment from "moment";
import React, { useContext } from "react";
import { MolliePayments } from "@mijnfotohoek-types";
import Modal from "./Modal";
import firebase from "../../firebase";

export const ModalPaymentHistory = ({ close, history }: { close: () => void; history: Array<MolliePayments> }) => {
  const { firebaseBaseUrl } = useContext(DashboardContext);
  const [loading, setLoading] = React.useState(null);

  const handleInvoice = async (i: number) => {
    setLoading(i);
    const token = await firebase.auth().currentUser.getIdToken();
    const res = await axios({
      url: `${firebaseBaseUrl}invoice`,
      method: "post",
      data: JSON.stringify({ ...history[i], user: firebase.auth().currentUser.email }),
      headers: { "Content-Type": "text/plain", Authorization: `Bearer ${token}` },
    });
    const url = `data:application/pdf;base64,${res.data}`;
    const link = document.createElement("a");
    link.href = url;
    link.download = `mijnfotohoek-factuur-${history[i].paymentId}.pdf`;
    link.click();
    setLoading(null);
  };
  return (
    <Modal title="Betaalgeschiedenis" close={close}>
      <table className="w-full mt-4">
        <thead className="font-bold ">
          <tr>
            <th>Bedrag</th>
            <th>Betaalnummer</th>
            <th>Datum</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {history.map((s, i) => (
            <tr key={i} className="border-b-2 border-solid border-slate-200">
              <td>€ {s.amount}</td>
              <td>{s.paymentId}</td>
              <td>{s.createdAt.toDate && moment(s.createdAt.toDate()).format("LL")}</td>
              <td>
                <Button loading={i === loading} onClick={() => handleInvoice(i)} className="my-1">
                  Factuur
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};
