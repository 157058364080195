import { motion } from "framer-motion";
import React from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { fadeInDown } from "utils/animations";

const Background = styled.div`
  position: fixed;
  z-index: ${(props: { zIndex: number }) => props.zIndex};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Content = styled.div`
  background-color: #fefefe;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: ${(props: { width: number }) => props.width}px;
`;

export default function Modal({
  width = 650,
  children,
  title,
  close,
  description,
  zIndex = 10,
  className = "",
}: {
  zIndex?: number;
  width?: number;
  children: any;
  title?: string;
  close?: () => void;
  className?: string;
  description?: string;
}) {
  return (
    <Background zIndex={zIndex}>
      <Content
        as={motion.div}
        variants={fadeInDown}
        width={width}
        className={`mt-8 md:mt-42 md:p-10 p-6 rounded-3xl ${className}`}
      >
        <div className="flex justify-between relative gap-4">
          <div>
            <h1 className="md:text-2xl text-xl font-semibold">{title}</h1>
            {description !== "" && <p className="text-gray-500">{description}</p>}
          </div>
          {close !== undefined && (
            <button className="absolute mt-1 hover:opacity-80 right-2 top-1 text-xl" onClick={close}>
              <FaTimes className="text-slate-500" />
            </button>
          )}
        </div>
        {children}
      </Content>
    </Background>
  );
}
