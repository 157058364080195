import { AlertStatus, useToast } from "@chakra-ui/react";
import React, { createContext, useEffect, useState } from "react";
import { HasEnoughStorage, HasValidFiles, RejectedFile, UserData } from "@mijnfotohoek-types";
import firebase from "../firebase";

interface DashboardContextInterface {
  firebaseBaseUrl: string;
  user: string;
  sendToast: (title: string, description: string, status: AlertStatus, duration?: number | null) => void;
  userData: UserData;
  checkFiles: (files: Array<File>) => HasValidFiles;
  checkStorage: (files: Array<File>) => HasEnoughStorage;
}

// @ts-ignore
export const DashboardContext = createContext<DashboardContextInterface>({
  firebaseBaseUrl:
    window.location.hostname === "localhost"
      ? `http://localhost:5555/fotoqr-b1a69/europe-west1/`
      : `https://europe-west1-fotoqr-b1a69.cloudfunctions.net/`,
});

const DashboardContextProvider = (props) => {
  const firebaseBaseUrl =
    window.location.hostname === "localhost"
      ? `http://localhost:5555/fotoqr-b1a69/europe-west1/`
      : `https://europe-west1-fotoqr-b1a69.cloudfunctions.net/`;
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState<UserData>(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(() => {
      if (firebase.auth().currentUser != null) {
        setUser(firebase.auth().currentUser.uid);
      }
    });
  }, []);

  useEffect(() => {
    if (user != null) {
      const unsubscribe = firebase
        .firestore()
        .collection("users")
        .doc(user)
        .onSnapshot((doc) => {
          setUserData(doc.data() as UserData);
        });
      return () => unsubscribe();
    }
  }, [user]);

  const toast = useToast();
  const sendToast = (title: string, description: string, status: AlertStatus, duration = 6000) => {
    toast({
      title,
      description,
      status,
      duration,
      isClosable: true,
    });
  };

  const checkStorage = (files): HasEnoughStorage => {
    const uploadSize = files.reduce((a, b) => a + b.size, 0);
    if (uploadSize + userData.storage > userData.storageLimit) {
      return {
        hasEnough: false,
        surpass: uploadSize + userData.storage - userData.storageLimit,
      };
    }
    return {
      hasEnough: true,
    };
  };

  const checkFiles = (files): HasValidFiles => {
    const size = 10000000;
    let rejected: RejectedFile = {};
    const allowedMimes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
    const allowedMimesReadable = allowedMimes.map((d) => d.split("/")[1]).join(", ");
    files.forEach((f) => {
      if (!allowedMimes.includes(f.type))
        rejected[f.name] = `Ongeldig bestandstype (toegestaan: ${allowedMimesReadable})`;
      //  rejected.push({ error: `Ongeldig bestandstype (toegestaan: ${allowedMimesReadable})`, file: f.name });

      // if (f.size > size) rejected.push({ error: `Bestand is te groot (maximaal ${size / 1000000} MB)`, file: f.name });
      if (f.size > size) rejected[f.name] = `Bestand is te groot (maximaal ${size / 1000000} MB)`;
    });
    return {
      hasValidFiles: Object.keys(rejected).length === 0,
      rejected,
    };
  };

  return (
    <DashboardContext.Provider value={{ firebaseBaseUrl, user, sendToast, userData, checkFiles, checkStorage }}>
      {props.children}
    </DashboardContext.Provider>
  );
};
export default DashboardContextProvider;
