import { CircularProgress } from "@chakra-ui/react";
import Button from "components/forms/Button";
import { Input } from "components/forms/Input";
import { LargeIcon } from "components/icons/LargeIcon";
import { PageSideBarLayout } from "components/layout/PageSideBarLayout";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { object, string } from "yup";
import firebase from "../../firebase";

const ResetPasswordForm = ({ handleReset, loading, error }) => {
  return (
    <Formik
      validationSchema={object({
        password: string().required("Vereist"),
        passwordConfirm: string().required("Vereist"),
      })}
      onSubmit={(values, actions) => handleReset(values, actions)}
      initialValues={{ password: "", passwordConfirm: "" }}
    >
      <Form>
        <div className="flex gap-4 flex-col">
          {error !== "" && <p className="text-red-500 font-bold">{error}</p>}
          <Input type="password" optional={false} label="Wachtwoord" name="password" />
          <Input type="password" optional={false} label="Wachtwoord bevestigen" name="passwordConfirm" />
          <Button type="submit" size="text-xl" loading={loading}>
            Resetten
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

const ResetPasswordSucces = () => {
  return (
    <div>
      <LargeIcon>
        <FaCheck />
      </LargeIcon>
      <p>Wachtwoord is gewijzigd. Je kan nu weer inloggen.</p>
      <Button className="mt-8">
        <Link to="/">Inloggen</Link>
      </Button>
    </div>
  );
};

const ResetPasswordValid = ({ oobCode }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [succes, setSucces] = useState(false);
  const handleReset = async (values, actions) => {
    setLoading(true);
    setError("");

    if (values.password != values.passwordConfirm) {
      actions.setFieldError("passwordConfirm", "Wachtwoorden komen niet overeen");
      setLoading(false);
      return;
    }
    if (values.password.length < 6) {
      actions.setFieldError("password", "Wachtwoord moet minimaal 6 karakters lang zijn");
      setLoading(false);
      return;
    }

    try {
      await firebase.auth().confirmPasswordReset(oobCode, values.password);
      setSucces(true);
    } catch {
      setError("Er is iets fout gegaan. Probeer het later opnieuw.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="max-w-md">
      {!succes && <ResetPasswordForm loading={loading} error={error} handleReset={handleReset} />}
      {succes && <ResetPasswordSucces />}
    </div>
  );
};

const ResetPasswordInvalid = () => {
  return (
    <div>
      <LargeIcon>
        <FaTimes />
      </LargeIcon>
      <p className="mb-8">Deze resetlink is ongeldig of verlopen.</p>
      <Button>
        <Link to="/">Naar login</Link>
      </Button>
    </div>
  );
};

export const ResetPasswordHandler = ({ oobCode }: { oobCode: string }) => {
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);

  const checkOobCode = async () => {
    try {
      await firebase.auth().verifyPasswordResetCode(oobCode);
      setValid(true);
    } catch (err) {
      setValid(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkOobCode();
  }, []);

  return (
    <PageSideBarLayout title={loading ? "" : valid ? "Wachtwoord resetten" : "Ongeldige resetlink"}>
      {loading && (
        <div className="mt-8">
          <CircularProgress isIndeterminate capIsRound={true} color="#3b82f6" size={100} thickness={8} />
        </div>
      )}
      {!loading && valid && <ResetPasswordValid oobCode={oobCode} />}
      {!loading && !valid && <ResetPasswordInvalid />}
    </PageSideBarLayout>
  );
};
