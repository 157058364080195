import Button from "components/forms/Button";
import React from "react";
import { Terms } from "./components/Terms";
import Modal from "./Modal";

export const ModalPrivacyPolicy = ({ close }) => {
  return (
    <Modal title="Privacy beleid" close={close}>
      <Terms>
        Mijnfotohoek.nl (Kamer van Koophandel: 72163216, contact via info@mijnfotohoek.nl) (hierna te noemen
        mijnfotohoek) verwerkt in beperkte mate persoonsgegevens van haar gebruikers. In deze privacyverklaring
        informeren wij u over de verwerking van uw persoonsgegevens door mijnfotohoek. Dit zijn gegevens die direct of
        indirect iets zeggen over u als persoon.
      </Terms>
      <Terms title="Accountregistratie (vereiste gegevens)">
        Zodra u een account aanmaakt, worden de volgende gegevens opgeslagen:
        <ul className="list-disc list-inside">
          <li>E-mailadres</li>
        </ul>
        Deze gegevens worden gebruikt om u toegang te geven tot uw account en om u te kunnen benaderen indien nodig.
        Voordat u gebruik kunt maken van de diensten van mijnfotohoek, dient u het opgegeven emailadres te verifieren
        volgens de instructies in het registratieproces. Uw emailadres blijft bewaart zolang u een account heeft bij
        mijnfotohoek. Uw emailadres wordt niet gedeeld met derden. Mocht u uw account willen verwijderen, dan kunt u
        contact opnemen met info@mijnfotohoek.nl
      </Terms>
      <Terms title="Abonnement (optioneel)">
        Na uw accountregistratie kunt u ervoor kiezen om een abonnement af te sluiten. Bij het afsluiten van een
        abonnement worden de volgende gegevens opgeslagen:
        <ul className="list-disc list-inside">
          <li>Naam</li>
          <li>Bankrekeningnummer</li>
          <li>Naam bankrekeninghouder</li>
        </ul>
        Mijnfotohoek heeft deze gegevens nodig om uw betaling te verwerken en uw account te upgraden. Deze gegevens
        worden, inclusief uw email adres, worden ook aan onze betalingsprovider Mollie (https://www.mollie.com/nl)
        verstrekt. Mollie is een betrouwbare en veilige partij die uw gegevens niet zal gebruiken voor andere doeleinden
        dan het verwerken van uw betalingen. Wanneer u uw abonnement opzegt, bewaart Mollie uw gegevens nog 12 maanden.
        U kunt via info@mijnfotohoek.nl een verzoek indienen om de gegevens direct te laten verwijderen.
      </Terms>
      <Terms title="Contactformulier (optioneel)">
        Wanneer u contact op neemt met mijnfotohoek via het contactfomulier worden de volgende gegevens opgeslagen:
        <ul className="list-disc list-inside">
          <li>Naam</li>
          <li>Inhoud van uw bericht</li>
        </ul>
        Mijnfotohoek heeft deze gegevens nodig om uw bericht te kunnen beantwoorden. Deze gegevens worden niet gedeeld
        met derden. Wij bewaren uw bericht 12 maanden.
      </Terms>
      <Terms title="Doel">
        <ul className="list-disc list-inside">
          <li>Het afhandelen van uw betaling</li>
          <li>Verzenden van onze nieuwsbrief en/of reclamefolder</li>
          <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
          <li>U te informeren over wijzigingen van onze diensten en producten</li>
          <li>U de mogelijkheid te bieden een account aan te maken</li>
          <li>Om goederen en diensten bij u af te leveren</li>
          <li>
            mijnfotohoek analyseert uw gedrag op de website om daarmee de website te verbeteren en het aanbod van
            producten en diensten af te stemmen op uw voorkeuren.
          </li>
          <li>
            mijnfotohoek verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij
            nodig hebben voor onze belastingaangifte.{" "}
          </li>
        </ul>
      </Terms>
      <Terms title="Cookies">
        mijnfotohoek gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij
        het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone.
        mijnfotohoek gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar
        behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om
        de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die uw surfgedrag
        bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden. Bij uw eerste bezoek aan onze
        website hebben wij u al geïnformeerd over deze cookies en toestemming gevraagd voor het plaatsen ervan. U kunt
        zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat.
        Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
        Zie voor een toelichting: https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/
        Op deze website worden ook cookies geplaatst door derden. Dit zijn bijvoorbeeld adverteerders en/of de sociale
        media-bedrijven. Hieronder een overzicht:
        <ul className="list-disc list-inside">
          <li>Cookie: Googly Analytics </li>
          <li>Naam:_utma</li>
          <li>Functie:Analytische cookie die websitebezoek meet</li>
          <li>Bewaartermijn:2 jaar</li>
        </ul>
      </Terms>
      <Terms title="Gegevens inzien, aanpassen of verwijderen">
        U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het
        recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de
        verwerking van uw persoonsgegevens door mijnfotohoek en heeft u het recht op gegevensoverdraagbaarheid. Dat
        betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een
        computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage,
        correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw
        toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar info@mijnfotohoek.nl. Om er zeker
        van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het
        verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan
        het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We
        reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. mijnfotohoek wil u er tevens op wijzen dat u
        de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit
        Persoonsgegevens. Dat kan via de volgende link:
        https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
      </Terms>
      <Terms title="Beveiliging">
        mijnfotohoek neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies,
        onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft
        dat uw gegevens niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze
        klantenservice of via info@mijnfotohoek.nl. mijnfotohoek heeft de volgende maatregelen genomen om uw
        persoonsgegevens te beveiligen:
        <ul className="list-disc list-inside">
          <li> Beveiligingssoftware, zoals een virusscanner en firewall.</li>
          <li>
            TLS (voorheen SSL) Wij versturen uw gegevens via een beveiligde internetverbinding. Dit kunt u zien aan de
            adresbalk 'https' en het hangslotje in de adresbalk.
          </li>
        </ul>
      </Terms>
      <Button onClick={close} className="mt-8">
        Sluiten
      </Button>
    </Modal>
  );
};
