import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { UploadContext } from "contexts/UploadContext";
import "css/thumb.scss";

export default function Image({ src, qr = "", index, setSelected }) {
  const [dragOver, setDragOver] = useState(false);
  // @ts-ignore
  const { setUploadResult, uploadResult } = useContext(UploadContext);
  const handleDragLeave = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setDragOver(false);
    }
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e) => {
    const i = parseInt(e.dataTransfer.getData("index"));
    let newUploadResult = uploadResult;
    const original = uploadResult[i];
    newUploadResult[i] = null;
    newUploadResult.splice(index + 1, 0, original);
    newUploadResult = newUploadResult.filter((v) => v !== null);
    //@ts-ignore
    setUploadResult(newUploadResult);
    setDragOver(false);
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOver(true);
  };

  return (
    <div className={`thumb_padding `} onDrop={(e) => handleDrop(e)}>
      <div className={`thumb_padding ${dragOver ? `thumb_dragover` : ``}`}>
        <div
          className={`thumb ${qr !== "" ? `qr` : ``} `}
          draggable="true"
          onDragStart={(e) => {
            e.dataTransfer.setData("index", index);
          }}
          onDragOver={(e) => handleDragOver(e)}
          onDragLeave={(e) => handleDragLeave(e)}
        >
          <div className="image" style={{ backgroundImage: `url(${src})` }}>
            <FontAwesomeIcon className="cursor-pointer" icon={faQrcode} onClick={() => setSelected(index)} />
          </div>
          <p>{qr !== "" ? qr : `Geen QR-code`}</p>
        </div>
      </div>
    </div>
  );
}
