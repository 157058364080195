import { Checkbox } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaClock, FaEnvelope, FaInfo, FaQrcode, FaUpload } from "react-icons/fa";
import Card from "components/cards/Card";
import Button from "components/forms/Button";
import Page from "components/layout/Page";
import firebase from "../firebase";
import { UpgradeUserFunctionData, UserPlan } from "@mijnfotohoek-types";
import { ModalTermsAndConditionsPremium } from "components/modals/ModalTermsAndConditionsPremium";
import axios from "axios";
import { DashboardContext } from "contexts/DashboardContext";
import { translatePeriodUnit } from "utils/translatePeriodUnit";

const Feature = ({ icon, title, desc }) => {
  return (
    <div className="flex gap-4 items-center">
      <div>
        <div className="bg-slate-200 text-blue-500 text-2xl p-3 rounded-xl">{icon}</div>
      </div>
      <div>
        <p className="text-slate-600 text-lg font-semibold">{title}</p>
        <p className="text-slate-400 ">{desc}</p>
      </div>
    </div>
  );
};

export default function Upgrade() {
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [plans, setPlans] = React.useState<UserPlan[]>([]);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { firebaseBaseUrl } = React.useContext(DashboardContext);
  const getPlans = async () => {
    const query = await firebase.firestore().collection("user_plans").get();
    const plans = query.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as UserPlan[];
    setPlans(plans.filter((p) => p.available));
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handleUpgrade = async () => {
    if (!termsAccepted) return;
    if (selectedPlan === null) return;
    setLoading(true);
    const token = await firebase.auth().currentUser.getIdToken();
    const params: UpgradeUserFunctionData = {
      plan: selectedPlan,
    };
    try {
      const res = await axios({
        method: "post",
        url: `${firebaseBaseUrl}upgradeUser`,
        params,
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${token}`,
        },
      });
      const redirect = res.data;
      // redirect to stripe
      window.location = redirect;
    } catch (err) {
      alert("Something went wrong");
      console.log(err);
      setLoading(false);
    }
  };

  const [modalTermsAndConditions, setModalTermsAndConditions] = React.useState(false);

  const { userData } = React.useContext(DashboardContext);

  return (
    <Page>
      {modalTermsAndConditions && <ModalTermsAndConditionsPremium close={() => setModalTermsAndConditions(false)} />}
      {plans.length != 0 && (
        <Card className="md:col-start-1 md:col-end-3 md:p-12" title="Premium account" border={false}>
          {userData.currentSubscription == null && userData.currentSubscriptionEnd != null && (
            <p className="bg-slate-200 text-slate-500 text-sm p-4 rounded-xl ">
              <FaInfo className="inline-block" /> Je hebt op dit moment nog een resterende dagen van je geanulleerde
              abonnement. Upgrade je nu, dan zal je eerste periode verlengd worden met de resterende dagen van je oude
              abonnement. Je betaalt dus niet onnodig teveel.
            </p>
          )}
          <div className="grid grid-cols-2 gap-8 ">
            <div className="flex gap-6 flex-col">
              <Feature icon={<FaQrcode />} title="Onbeperkt aanmaken" desc="Creeër zoveel QR-codes als je wilt" />
              <Feature icon={<FaUpload />} title="Veel opslag" desc={`Je krijgt bijna 10 GB extra opslag`} />
              <Feature icon={<FaEnvelope />} title="Sneller support" desc="Sneller antwoord op je vragen" />
              {/* <Feature icon={<FaChartLine />} title="Statistieken" desc="Inzicht in downloadstatistieken" /> */}
              <Feature
                icon={<FaClock />}
                title="Langer downloaden"
                desc={`QR-codes blijven 3 maanden geldig, in plaats van maximaal een maand.`}
              />
            </div>
            <div>
              <div className="border-slate-200 border-solid border-2 rounded-3xl p-8 flex flex-col gap-4">
                <p className="text-3xl text-slate-800 font-semibold">Kies je betaling</p>
                <div className="flex flex-col">
                  {plans.map((p) => (
                    <Checkbox
                      onChange={() => setSelectedPlan(p.id)}
                      isChecked={selectedPlan === p.id}
                      borderColor="#cbd5e1"
                    >
                      <div className="flex gap-2 items-center">
                        <h3 className="text-2xl text-slate-700">€{p.price}</h3>
                        <p className="text-slate-400">
                          / {translatePeriodUnit(p.periodLengthUnit, p.periodLengthValue)}
                        </p>
                      </div>
                    </Checkbox>
                  ))}
                </div>
                <p className="text-slate-400 text-xs">
                  Je wordt direct gevraagd om een betaling te doen. Daarna zullen de betalingen via automatische incasso
                  gaan. Opzeggen kan wanneer je wil. Terugbetalingstermijn is 14 dagen na aankoop.
                </p>
                <div className="flex gap-2 items-center">
                  <Checkbox
                    borderColor="#cbd5e1"
                    onChange={() => setTermsAccepted(!termsAccepted)}
                    isChecked={termsAccepted}
                  >
                    <p>
                      Ik ga akkoord met de{" "}
                      <button onClick={() => setModalTermsAndConditions(true)} className="underline">
                        voorwaarden
                      </button>
                    </p>
                  </Checkbox>
                </div>
                <Button onClick={handleUpgrade} loading={loading}>
                  Upgraden
                </Button>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Page>
  );
}
