import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBTG8GDlJnRWcM_0nt5M6zKiV02eWmCSgw",
  authDomain: "fotoqr-b1a69.firebaseapp.com",
  projectId: "fotoqr-b1a69",
  storageBucket: "fotoqr-b1a69.appspot.com",
  messagingSenderId: "416412483729",
  appId: "1:416412483729:web:22760762d0e4149d0ebf30",
  measurementId: "G-GJ490PVW34",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
