import React, { useContext, useState } from "react";
import CardDashboardCodes from "components/cards/CardDashboardCodes";
import CardDashboardHistory from "components/cards/CardDashboardHistory";
import CardDashboardPhotos from "components/cards/CardDashboardPhotos";
import "css/dashboard.scss";
import UploadContextProvider from "contexts/UploadContext";
import Page from "components/layout/Page";
import CodesContextProvider from "contexts/CodesContext";
import { stagger } from "utils/animations";
import { motion } from "framer-motion";
import { DashboardErrorMessage } from "components/DashboardErrorMessage";
import { FaInfoCircle } from "react-icons/fa";
import { DashboardContext } from "contexts/DashboardContext";
import moment from "moment";

const Dashboard = () => {
  const { userData } = useContext(DashboardContext);
  const [expires] = useState(moment(userData.currentSubscriptionEnd, "YYYY-MM-DD").fromNow());
  return (
    <Page>
      <motion.div variants={stagger} className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10">
        <DashboardErrorMessage />
        {userData.currentSubscription == null && userData.currentSubscriptionEnd != null && (
          <div className="w-full bg-amber-50 z-10 relative p-6 rounded-xl text-amber-500  col-start-1 md:col-end-3 col-end-4">
            <p className="text-xl font-bold">
              <FaInfoCircle className="inline-block" /> Je abonnement verloopt {expires}
            </p>
            <p>
              Je geanulleede abonnement verloopt op {moment(userData.currentSubscriptionEnd, "YYYY-MM-DD").format("LL")}
              .
            </p>
          </div>
        )}
        <CodesContextProvider>
          <CardDashboardCodes />
        </CodesContextProvider>
        <div></div>
        {/* <CardDashboardStats /> */}
        <UploadContextProvider>
          <CardDashboardPhotos />
        </UploadContextProvider>
        <CardDashboardHistory />
      </motion.div>
    </Page>
  );
};

export default Dashboard;
