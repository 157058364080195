import React from "react";

export const Laptop = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="52.88" height="38.74" viewBox="0 0 52.88 38.74">
      <g id="Layer_2" data-name="Layer 2" transform="translate(0)">
        <g id="printer">
          <path
            id="Path_47"
            data-name="Path 47"
            d="M6.45,49.07H49.93a1.2,1.2,0,0,0,1.19-1.22V18.76a1.21,1.21,0,0,0-1.19-1.22H6.45a1.21,1.21,0,0,0-1.2,1.22V47.85a1.21,1.21,0,0,0,1.2,1.22Z"
            transform="translate(-1.76 -17.54)"
            fill="#212528"
          />
          <path
            id="Path_48"
            data-name="Path 48"
            d="M1.76,53.35A2.91,2.91,0,0,0,4.4,56.28H52a3,3,0,0,0,2.64-2.93Z"
            transform="translate(-1.76 -17.54)"
            fill="#212528"
          />
          <path id="Path_49" data-name="Path 49" d="M48.43,31.54H4.4L0,35.81H52.83Z" fill="#ddd" />
          <path id="Path_50" data-name="Path 50" d="M46.97,31.88H5.88L4.42,33.67H48.44Z" fill="#bfbebe" />
          <path id="Path_51" data-name="Path 51" d="M30.94,34.46H21.91l-.68.9H31.62Z" fill="#212528" />
          <rect
            id="Rectangle_725"
            data-name="Rectangle 725"
            width="40.5"
            height="26.22"
            transform="translate(6.18 2.66)"
            fill="#3e4347"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M28.19,18.22a.69.69,0,1,1-.69.69.69.69,0,0,1,.69-.69Z"
            transform="translate(-1.76 -17.54)"
            fill="#ddd"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M32,55.26H24.37a.81.81,0,0,1-.95-.89h9.51a.82.82,0,0,1-.93.89Z"
            transform="translate(-1.76 -17.54)"
            fill="#3e4347"
          />
        </g>
      </g>
    </svg>
  );
};
