import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashboardContext } from "contexts/DashboardContext";
import fileSize from "filesize";
import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
export const Dropzone = ({ files, setFiles }) => {
  const [dragging, setDragging] = React.useState(false);
  const { userData } = React.useContext(DashboardContext);
  const [id] = React.useState(uuid());
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const newFiles = sort([...files, ...e.dataTransfer.files]);
    setFiles(newFiles);
  };

  const sort = (arr) => {
    return arr.sort(function (a, b) {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
  };

  const handleDragIn = (e) => {};
  return (
    <div className="">
      <div
        className={`h-full flex text-center items-center justify-center p-8 font-semibold flex-col border-2 border-solid rounded-xl ${
          dragging ? `border-blue-500 ` : `border-slate-200 `
        }`}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <FontAwesomeIcon className="text-4xl text-blue-500" icon={faCloudUploadAlt} />
        <h1 className="text-xl">
          {` `}
          Sleep hierheen of{" "}
          <label htmlFor={"fileUpload" + id} className="link cursor-pointer underline ">
            kies bestanden
          </label>
        </h1>
        <div className="text-slate-400 text-sm">
          <p>
            Je kan nog{" "}
            <span className="text-blue-500">
              {fileSize(Math.max(userData.storageLimit - userData.storage, 0), { exponent: 2 })}{" "}
            </span>{" "}
            uploaden.{" "}
          </p>
          {userData.currentSubscription == null && (
            <p>
              <Link to="/dashboard/upgrade" className="underline">
                Upgrade
              </Link>{" "}
              voor meer opslag.
            </p>
          )}
        </div>
      </div>
      <input
        onChange={(e) => {
          setFiles(sort([...files, ...(e.target.files as unknown as Array<any>)]));
        }}
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        id={"fileUpload" + id}
        multiple
      />
    </div>
  );
};
