import { motion } from "framer-motion";
import React from "react";
import { fadeInDown } from "../../utils/animations";

export default function Card({
  children,
  title,
  className = "",
  border = true,
  flex = true,
}: {
  children: any;
  title?: string;
  className?: string;
  border?: Boolean;
  flex?: Boolean;
}) {
  return (
    <motion.div
      variants={fadeInDown}
      className={`${className} relative p-8 bg-white  rounded-3xl text-left`}
      style={{
        boxShadow: "0px 5px 20px rgba(0,0,0,0.15)",
      }}
    >
      <div className={`${flex ? `flex flex-col gap-4` : ``}`}>
        {title != null && (
          <h1
            className={`text-xl ${border && `rounded_border_bottom`} font-bold text-blue-500 md:text-2xl pb-2`}
            style={
              {
                // color: "#073C92",
              }
            }
          >
            {title}
          </h1>
        )}
        {children}
      </div>
    </motion.div>
  );
}
