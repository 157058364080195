import React from "react";

export const PageSideBarLayout = ({ children, title }: { children: React.ReactNode; title?: string }) => {
  return (
    <div>
      <div
        className="h-screen w-full flex"
        style={{
          backgroundImage: "url(/background.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-white h-screen xl:w-1/2 w-full md:w-[66%] ml-auto md:rounded-l-3xl md:p-16 p-8">
          <a href="https://www.mijnfotohoek.nl/" className="inline-block">
            <h1 className="font-semibold text-2xl text-blue-500">mijnfotohoek.nl</h1>
          </a>
          {title && <h1 className="text-4xl my-8 font-bold text-blue-500">{title}</h1>}
          {children}
        </div>
      </div>
    </div>
  );
};
