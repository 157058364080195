import React, { useState, createContext, useContext, SetStateAction, Dispatch } from "react";
import axios from "axios";
import { DashboardContext } from "./DashboardContext";
import firebase from "../firebase";
import { Photo, RejectedFile } from "@mijnfotohoek-types";

type UploadAlert = {
  title: string;
  message: string;
  show: boolean;
};
interface UploadContextInterface {
  setFiles: Dispatch<SetStateAction<any[]>>;
  uploadingProgress: number;
  files: Array<File>;
  uploading: Boolean;
  setUploading: Dispatch<SetStateAction<Boolean>>;
  upload: () => void;
  uploadResult: Array<Photo>;
  setUploadResult: Dispatch<SetStateAction<Photo[]>>;
  rejectedFiles: Array<RejectedFile>;
  setRejectedFiles: Dispatch<SetStateAction<any[]>>;
  storageSurpass: number;
  setStorageSurpass: Dispatch<SetStateAction<number>>;
  resetUpload: () => void;
  modalUploadAlert: UploadAlert;
  setModalUploadAlert: Dispatch<SetStateAction<UploadAlert>>;
}

export const UploadContext = createContext<UploadContextInterface>(null);

const UploadContextProvider = (props) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [uploading, setUploading] = useState<Boolean>(false);
  const [rejectedFiles, setRejectedFiles] = useState<Array<RejectedFile>>([]);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [storageSurpass, setStorageSurpass] = useState<number>(0);
  const [uploadResult, setUploadResult] = useState<Array<Photo>>([]);
  const { firebaseBaseUrl, sendToast } = useContext(DashboardContext);
  const defaultAlert = {
    show: false,
    title: "",
    message: "",
  };
  const [modalUploadAlert, setModalUploadAlert] = useState(defaultAlert);

  const upload = async () => {
    setModalUploadAlert(defaultAlert);
    if (files.length === 0) {
      setModalUploadAlert({
        show: true,
        title: "Geen foto's geselecteerd",
        message: "Selecteer eerst foto's om te uploaden",
      });

      return;
    }
    if (files.length === 1) {
      setModalUploadAlert({
        show: true,
        title: "Minstens 2 foto's nodig",
        message:
          "Voor het koppelen van de foto's aan de QR-codes heb je minstens 2 foto's nodig: 1 QR-code en 1 foto die bij die QR-code hoort. Kijk voor meer uitleg op de support-pagina.",
      });
      return;
    }
    setUploading(true);
    setUploadingProgress(0);
    const token = await firebase.auth().currentUser.getIdToken(true);
    let uploadedPhotos: Array<Photo> = [];
    for (let i = 0; i < files.length; i++) {
      setUploadingProgress(i + 1);
      try {
        // make request to upload endpoint; returns corresponding firestore documents
        const res = await axios({
          url: `${firebaseBaseUrl}upload`,
          data: files[i],
          method: "post",
          params: { fileName: files[i].name },
          headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
        });
        const data = res.data as Photo;
        uploadedPhotos.push(data);
      } catch (err) {
        // internal server error
        sendToast(`${files[i].name} niet geüpload`, `Dit bestand is niet geüpload door een server fout`, "error", null);
      }
    }
    setUploadResult(uploadedPhotos);
    setUploading(false);
    setUploadingProgress(0);
  };

  const resetUpload = () => {
    setFiles([]);
    setUploadResult([]);
    setUploading(false);
    setRejectedFiles([]);
    setStorageSurpass(0);
    // reset DOM file upload??
  };

  return (
    <UploadContext.Provider
      value={{
        setFiles,
        uploadingProgress,
        files,
        uploading,
        setUploading,
        upload,
        uploadResult,
        setUploadResult,
        rejectedFiles,
        storageSurpass,
        resetUpload,
        setStorageSurpass,
        setRejectedFiles,
        modalUploadAlert,
        setModalUploadAlert,
      }}
    >
      {props.children}
    </UploadContext.Provider>
  );
};
export default UploadContextProvider;
