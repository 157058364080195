import Button from "components/forms/Button";
import React from "react";
import Modal from "./Modal";

export const ModalPrompt = ({
  title,
  description,
  close,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  loading = false,
  children,
}: {
  title: string;
  description?: string;
  close?: () => void;
  cancelText?: string;
  confirmText: string;
  onConfirm: () => void;
  onCancel?: () => void;
  loading?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <Modal title={title} description={description} close={close} width={450} zIndex={20}>
      <div className="my-8">{children}</div>
      <div className="flex justify-between mt-8">
        {cancelText && (
          <Button color="bg-transparent text-blue-500" className="px-0" onClick={onCancel ?? close}>
            {cancelText}
          </Button>
        )}
        <Button onClick={onConfirm} loading={loading}>
          {confirmText}
        </Button>
      </div>
    </Modal>
  );
};
