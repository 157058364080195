import { useToast } from "@chakra-ui/react";
import Button from "components/forms/Button";
import { LargeIcon } from "components/icons/LargeIcon";
import { PageSideBarLayout } from "components/layout/PageSideBarLayout";
import React, { useEffect, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";

export const VerifyEmailPlaceholder = () => {
  const [email, setEmail] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        if (user.emailVerified) history.push("/");
        setEmail(user.email);
      } else {
        history.push("/");
      }
    });

    return () => {
      listener();
    };
  }, []);

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const resendVerification = async () => {
    setLoading(true);
    if (firebase.auth().currentUser.emailVerified) {
      history.push("/dashboard");
      return;
    }
    try {
      await firebase.auth().currentUser.sendEmailVerification();
      toast({
        title: "Email is opnieuw verzonden!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch {
      toast({
        title: "Probleem met het verzenden van de email",
        description: "Controleer of je al een email hebt ontvangen",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <PageSideBarLayout title="Verifieer je email">
      <LargeIcon>
        <FaEnvelope />
      </LargeIcon>
      <p className="mb-8 text-slate-500 max-w-xl">
        Voordat je verder kunt gaan, moet je je e-mailadres verifiëren. We hebben je een e-mail gestuurd naar {` `}
        <span className="text-blue-500 font-semibold">{email}</span>. Klik op de link in de e-mail om je e-mailadres te
        verifiëren.
      </p>
      <Button onClick={resendVerification} loading={loading}>
        Opnieuw verzenden
      </Button>
      <div>
        <Button onClick={() => firebase.auth().signOut()} color="mt-8 bg-transparent text-blue-500">
          Uitloggen
        </Button>
      </div>
    </PageSideBarLayout>
  );
};
