import React, { useContext, useEffect, useState } from "react";
import Page from "components/layout/Page";
import Card from "components/cards/Card";
import { Form, Formik } from "formik";
import Button from "components/forms/Button";
import { Input } from "components/forms/Input";
import { DashboardContext } from "contexts/DashboardContext";
import firebase from "../firebase";
import fileSize from "filesize";
import { TableRow } from "components/layout/TableRow";
import { stagger } from "utils/animations";
import { motion } from "framer-motion";
import prettyBytes from "pretty-bytes";
import { ModalChangePassword } from "components/modals/ModalChangePassword";
import { Link, useLocation } from "react-router-dom";
import { Subscription } from "@mijnfotohoek-types";
import moment from "moment";
import axios from "axios";
import { ModalPrompt } from "components/modals/ModalPrompt";
import { ModalPaymentHistory } from "components/modals/ModalPaymentHistory";
import { ModalTermsAndConditionsRegister } from "components/modals/ModalTermsAndConditionsRegister";
import { ModalPrivacyPolicy } from "components/modals/ModalPrivacyPolicy";
import { ModalTermsAndConditionsPremium } from "components/modals/ModalTermsAndConditionsPremium";
import { translatePeriodUnit } from "utils/translatePeriodUnit";

// @ts-ignore
const SubscriptionSection = ({ s }: { s: Subscription }) => {
  const { firebaseBaseUrl, sendToast } = useContext(DashboardContext);
  const [loading, setLoading] = useState(false);
  const [modalConfirmCancel, setModalConfirmCancel] = useState(false);
  const cancelSubscription = async () => {
    setLoading(true);
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      await axios({
        method: "post",
        url: `${firebaseBaseUrl}cancelSubscription`,
        params: {
          subscriptionId: s.id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      sendToast("Je abonnement is opgezegd", null, "success");
    } catch (err) {
      console.log(err);
      sendToast("Er is iets fout gegaan", null, "error");
    }
    setModalConfirmCancel(false);
    setLoading(false);
  };

  return (
    <div className="rounded-[27px] border-solid border-2 border-slate-200 text-slate-500 ">
      {modalConfirmCancel && (
        <ModalPrompt
          title="Weet je het zeker?"
          description="Weet je zeker dat je je abonnement wilt opzeggen?"
          confirmText="Ja, opzeggen"
          cancelText="Nee, terug"
          onConfirm={cancelSubscription}
          onCancel={() => setModalConfirmCancel(false)}
          loading={loading}
        />
      )}
      {}
      <div className="text-lg flex justify-between w-full rounded-3xl px-6 py-8 bg-slate-200 font-semibold">
        <div className="flex gap-2 items-center">
          <p>{s.subscriptionName.toUpperCase()}</p>
          {s.canceledAt != null && <div className="bg-amber-100 text-amber-500 py-1 px-4 rounded-lg">Opgezegd</div>}
        </div>
        <p>
          € {s.price}/{translatePeriodUnit(s.periodLengthUnit, s.periodLengthValue)}
        </p>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-2 mb-8">
          <div>
            <p className="text-slate-400">Gestart op</p>

            <p className="text-slate-600 font-semibold">
              {s.createdAt.toDate && moment(s.createdAt.toDate()).format("D MMM YYYY")}
            </p>
          </div>
          <div>
            <p className="text-slate-400">Huidige periode</p>
            <p className="text-slate-600 font-semibold">
              {moment(s.currentPeriodStart, "YYYY-MM-DD").format("D MMM YYYY")} -{" "}
              {moment(s.currentPeriodEnd, "YYYY-MM-DD").format("D MMM YYYY")}
            </p>
          </div>
        </div>
        <div className="mt-4">
          {s.canceledAt == null && (
            <Button onClick={() => setModalConfirmCancel(true)} loading={loading}>
              Opzeggen
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default function Account() {
  const loc = useLocation();
  const [params] = useState(new URLSearchParams(loc.search));
  const { user, sendToast, userData } = useContext(DashboardContext);
  const [loading, setLoading] = useState(false);
  // @ts-ignore
  const [modalChangePassword, setModalChangePassword] = useState(params.get("resetPassword") ? true : false);
  const [subscriptions, setSubscriptions] = useState<Array<Subscription>>([]);
  const [modalPaymentHistory, setModalPaymentHistory] = useState(false);

  useEffect(() => {
    if (user != null) {
      const unsubscribe = firebase
        .firestore()
        .collection("subscriptions")
        .where("user", "==", user)
        .orderBy("createdAt", "desc")
        .orderBy("canceledAt", "desc")
        .onSnapshot((snapshot) => {
          setSubscriptions(
            snapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() } as Subscription;
            })
          );
        });
      return () => unsubscribe();
    }
  }, [user]);

  const handleChangePreferences = async (values) => {
    setLoading(true);
    try {
      await firebase.firestore().collection("users").doc(user).update({ preferences: values });
      sendToast("Gelukt", "Je wijzigingen zijn opgeslagen", "success");
    } catch (err) {
      console.log(err);
      sendToast("Mislukt", "Wijzigingen niet opgeslagen door een serverfout. Probeer het opnieuw.", "error");
    }
    setLoading(false);
  };

  const [showTerms, setShowTerms] = useState(null);

  return userData == null ? null : (
    <Page>
      <motion.div variants={stagger} className="grid grid-cols-[3fr,2fr] gap-5">
        <Card border={false} title="Account">
          <table className="table-auto border-collapse">
            <tbody>
              <TableRow rowKey="Email">
                <p>{firebase.auth().currentUser.email}</p>
              </TableRow>
              <TableRow rowKey="Gebruikers id">
                <p>{firebase.auth().currentUser.uid}</p>
              </TableRow>
              <TableRow rowKey="Verbruikte opslag">
                <div className="flex gap-2 items-center">
                  <p className="">{fileSize(userData.storage, { exponent: 2 })}</p>
                  <p className="text-slate-400">
                    {" "}
                    (maximum: {prettyBytes(userData.storageLimit == null ? 0 : userData.storageLimit)},{" "}
                    {Math.floor((userData.storage / userData.storageLimit) * 100)}% verbruikt)
                  </p>
                </div>
              </TableRow>
              <TableRow rowKey="Aangemaakt QR-codes">
                <div className="flex gap-2 items-center">
                  <p className="">{userData.codes}</p>
                  <p className="text-slate-400">
                    {" "}
                    (maximum: {userData.codesLimit === null ? `onbeperkt` : userData.codesLimit})
                  </p>
                </div>
              </TableRow>
              <TableRow rowKey="Aantal geüploade foto's">
                <div className="flex gap-2 items-center">
                  <p className="">{userData.photos}</p>
                  <p className="text-slate-400">
                    {" "}
                    (maximum: {userData.photosLimit === null ? `onbeperkt` : userData.photosLimit})
                  </p>
                </div>
              </TableRow>
            </tbody>
          </table>
          {modalChangePassword && <ModalChangePassword close={() => setModalChangePassword(false)} />}
          <div>
            <Button onClick={() => setModalChangePassword(true)}>Wachtwoord wijzigen</Button>
          </div>
        </Card>
        {userData.currentSubscription == null && (
          <div>
            <Card border={false} title="Upgraden👑">
              <p>
                Onbeperkt QR-codes aanmaken, extra opslag en nog meer. Upgrade je account naar premium en krijg direct
                toegang tot exclusieve functionaliteiten.
              </p>
              <Link to="/dashboard/upgrade">
                <Button>Upgrade nu</Button>
              </Link>
            </Card>
          </div>
        )}

        <Card className="row-start-3" border={false} title="Voorkeuren downloadkaartjes en -pagina">
          <Formik
            onSubmit={(values) => handleChangePreferences(values)}
            initialValues={{
              card_language: userData.preferences.card_language,
              downloadpage_language: userData.preferences.downloadpage_language,
              card_contact_details: userData.preferences.card_contact_details,
              card_upload_period: userData.preferences.card_upload_period,
              card_instruction_page: userData.preferences.card_instruction_page,
            }}
          >
            <Form>
              <table className="table-auto border-collapse w-full">
                <tbody>
                  <TableRow
                    rowKey="Instructiepagina"
                    toolTip="Telkens als je een fotohoek aanmaakt, krijg je een bestand met QR-codes. Op de eerste pagina van dit bestand staan altijd een paar instructies. Via deze optie kan je die pagina verbergen."
                  >
                    <Input
                      optional={false}
                      name="card_instruction_page"
                      options={[
                        {
                          label: "Weergeven",
                          value: "visible",
                        },
                        {
                          label: "Verbergen",
                          value: "hidden",
                        },
                      ]}
                    />
                  </TableRow>
                  {/* <TableRow
                    rowKey="Taal downloadkaartje"
                    toolTip="De taal van de instructies op de downloadkaartjes met de QR-codes"
                  >
                    <Input
                      optional={false}
                      name="card_language"
                      options={[
                        {
                          label: "Nederlands",
                          value: "dutch",
                        },
                        {
                          label: "Frans",
                          value: "france",
                        },
                        {
                          label: "Duits",
                          value: "german",
                        },
                        {
                          label: "Engels",
                          value: "english",
                        },
                      ]}
                    />
                  </TableRow> */}
                  {/* <TableRow
                    rowKey="Taal downloadpagina"
                    toolTip="De taal van de pagina waar mensen heen gestuurd worden als ze hun QR-code scannen"
                  >
                    <Input
                      optional={false}
                      name="downloadpage_language"
                      options={[
                        {
                          label: "Nederlands",
                          value: "dutch",
                        },
                        {
                          label: "Frans",
                          value: "france",
                        },
                        {
                          label: "Duits",
                          value: "german",
                        },
                        {
                          label: "Engels",
                          value: "english",
                        },
                      ]}
                    />
                  </TableRow> */}
                  <TableRow
                    rowKey="Contactgegevens"
                    toolTip="Deze contactgegevens komen op het downloadkaartje te staan. Mensen kunnen je op die manier contacteren wanneer zij vragen hebben over je foto's. Je mag zelf weten wat je hier invult, bijvoorbeeld een emailadres of een telefoonnummer."
                  >
                    <Input optional={false} name="card_contact_details" />
                    <p className="text-xs text-slate-400">
                      Laat veld leeg om geen contactgegevens te tonen op downloadkaartje
                    </p>
                  </TableRow>
                  <TableRow
                    rowKey="Gemiddelde uploadtijd"
                    toolTip="Geef aan hoe lang het ongeveer duurt voordat je de foto's geüpload hebt. Deze informatie komt op het downloadkaartje te staan."
                  >
                    <Input
                      optional={false}
                      name="card_upload_period"
                      options={[
                        {
                          label: "1 tot 5 dagen",
                          value: "1 tot 5 dagen",
                        },
                        {
                          label: "1 tot 4 dagen",
                          value: "1 tot 4 dagen",
                        },
                        {
                          label: "1 tot 3 dagen",
                          value: "1 tot 3 dagen",
                        },
                        {
                          label: "1 tot 2 dagen",
                          value: "1 tot 2 dagen",
                        },
                        {
                          label: "1 dag",
                          value: "1 dag",
                        },
                      ]}
                    />
                  </TableRow>
                </tbody>
              </table>
              <Button
                type="submit"
                color="bg-blue-200 text-blue-500 inline-block w-auto mt-2"
                size="text-lg"
                loading={loading}
              >
                Wijzigen opslaan
              </Button>
            </Form>
          </Formik>
        </Card>

        <Card className="row-start-4 col-end-2 col-start-1" border={false} title="Abonnement">
          {modalPaymentHistory && (
            <ModalPaymentHistory history={userData.molliePayments} close={() => setModalPaymentHistory(false)} />
          )}
          {subscriptions.length > 0 && (
            <div>
              <Button onClick={() => setModalPaymentHistory(true)}>Facturen</Button>
            </div>
          )}
          {subscriptions.length === 0 && <p>Je hebt nog geen recente abonnementen</p>}
          {subscriptions.map((s, i) => (
            <SubscriptionSection key={s.id} s={s} />
          ))}
        </Card>
        <Card className="row-start-5 col-end-2 col-start-1" title="Algemene voorwaarden en privacy beleid">
          {showTerms === "terms" && <ModalTermsAndConditionsRegister close={() => setShowTerms(null)} />}
          {showTerms === "privacy_policy" && <ModalPrivacyPolicy close={() => setShowTerms(null)} />}
          {showTerms === "terms_subscription" && <ModalTermsAndConditionsPremium close={() => setShowTerms(null)} />}
          <div>
            <Button onClick={() => setShowTerms("terms")}>Algemene voorwaarden </Button>
          </div>
          <div>
            <Button onClick={() => setShowTerms("terms_subscription")}>Algemene voorwaarden abonnement</Button>
          </div>
          <div>
            <Button onClick={() => setShowTerms("privacy_policy")}>Privacy beleid</Button>
          </div>
        </Card>
      </motion.div>
    </Page>
  );
}
